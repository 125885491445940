import { $api } from 'environ';
import { useCallback, useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import login from 'store/ducks/login';
import { types } from 'store/ducks/ws';
import { v4 as uuid} from 'uuid';

type WebSocketHolder = {
    [url: string]: {
        socket: WebSocket | null,
        connectionRequests: number,
        reconnectAttempts: number,
        reconnectInterval: number,
        currentRetries: number,
        onOpen?: (event: WebSocketEventMap['open']) => void,
        onClose?: (event: WebSocketEventMap['close']) => void,
        onMessage?: (event: WebSocketEventMap['message']) => void,
        onError?: (event: WebSocketEventMap['error']) => void,
        onReconnectStop?: (numAttempted: number) => void,
        shouldReconnect?: boolean,
        queue: string[]
    }
}

const ws: WebSocketHolder = {
    [$api]: {
        socket: null,
        connectionRequests: 0,
        currentRetries: 0,
        reconnectInterval: 5000,
        reconnectAttempts: 20,
        shouldReconnect: true,
        queue: []
    }
}

setInterval( () => {
    const {socket, queue} = ws[$api]
    if (queue.length > 0) {
        if (socket && socket.readyState === socket.OPEN) {
            while (queue.length > 0) {
                socket.send(queue[0]);
                queue.shift();
            }
        }
    }   
}, 1000)

setInterval( () => {
    const {socket} = ws[$api];
    try { 
        socket?.send('keepalive')
    } catch(err) {
        return
    }
    
}, 10000)

const useWS = () => {
    const dispatch = useDispatch();
    const [lastJsonMessage, setLastJsonMessage] = useState(null as string | null)

    useEffect(() => {
        ws[$api].shouldReconnect = true;
        openSocket();
        ws[$api].connectionRequests ++;
        return handleDerender
    }, [])

    function handleDerender() {
        ws[$api].connectionRequests --;
        if ( ws[$api].connectionRequests <= 0 ) {
            ws[$api].connectionRequests = 0;
            ws[$api].shouldReconnect = false;
            if (ws[$api].socket) {
                ws[$api].socket!.close()
            }
        }
    }

    function openSocket() {
        if (!ws[$api].shouldReconnect) return
        if (ws[$api].socket) return;
        // eslint-disable-next-line
        // @ts-ignore
        const socket =  new WebSocket($api);
        ws[$api].socket = socket;
        socket.onopen = (event) => {
            dispatch( {type: types.OPEN} );
            ws[$api].currentRetries = 0;
        };
        socket.onclose = event => handleSocketClose(ws[$api], event)
        socket.onmessage = (event) => {
            const data = JSON.parse( event.data )
            if (data?.forceLogin) {
                dispatch({ type: 'RESET' })
            }
            dispatch( { type: types.MESSAGE, payload: data } )

        }
        socket.onerror = (event) => { dispatch( {type: types.ERROR}); socket.close()}
    }

    function handleSocketClose(socketConfig: WebSocketHolder[''], event: WebSocketEventMap['close']) {
        console.log('socket closed', event)
        dispatch( {type: types.CLOSE} );
        socketConfig.currentRetries ++;
        socketConfig.socket = null;
        if (socketConfig.currentRetries > socketConfig.reconnectAttempts) {
            onReconnectStop(socketConfig.currentRetries)
        } else {
            setTimeout( () => {
                openSocket()
            }, socketConfig.reconnectInterval)
        }
    }
    
    function onReconnectStop(numAttempted: number) {
        dispatch( login.actions.setLogin(false) )
    }

    const socket = ws[$api].socket;

    const send = useCallback( (message: Record<string, any>) => {
        const requestId = uuid();
        const messageToSend = JSON.stringify({...message, requestId});
        if (socket && socket.readyState === socket.OPEN) {
            socket.send(messageToSend);
            setLastJsonMessage(messageToSend)
        } else {
            ws[$api].queue.push(messageToSend);
        }
        return requestId;
     }, [socket])

     const reply = useMemo(() => ({
         socket,
         send,
         lastJsonMessage,
         readyState: socket?.readyState
     }), [socket, send, lastJsonMessage, socket?.readyState]);

     return reply
}

export default useWS;