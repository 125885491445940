import {FC} from 'react';
import SpinnerLogo from 'components/SpinnerLogo';

interface Props {

}
const Loading: FC<Props> = ({}) => ( // eslint-disable-line no-empty-pattern
    <div style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center'}}>
        <div style={{flexDirection: 'column', alignSelf: "center", display: 'flex'}}>
            <SpinnerLogo width={100} style={{margin: 'auto'}} /> 
        </div>
    </div>  
);

export default Loading;
