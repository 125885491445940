import { combineReducers } from 'redux';
import {
    MWage,
    InvoicePaymentModel,
    MLedger,
    MExpenseReport,
    MPaystub,
    MContract,
    MInvoice,
    MPayPeriod,
    MRateSheet
} from 'store/types/models/finance';
import { BaseDuck } from './BaseDuck';

export const wages = new BaseDuck<MWage>('wages');

export const contracts = new BaseDuck<MContract>('contracts');

export const invoices = new BaseDuck<MInvoice>('invoices');

export const invoicePayments = new BaseDuck<InvoicePaymentModel>('invoicePayments');

export const expenses = new BaseDuck<MExpenseReport>('expenses');

export const ledgers = new BaseDuck<MLedger>('ledgers');

export const payPeriods = new BaseDuck<MPayPeriod>('payPeriods');

export const paystubs = new BaseDuck<MPaystub>('paystubs');

export const rateSheets = new BaseDuck<MRateSheet>('rateSheets');

export const reducer = combineReducers({
    wages: wages.reducer.bind(wages),
    contracts: contracts.reducer.bind(contracts),
    // subcontracts: subcontracts.reducer,
    // invoices: invoices.reducer,
    // invoicePayments: invoicePayments.reducer,
    expenses: expenses.reducer.bind(expenses),
    ledgers: ledgers.reducer.bind(ledgers),
    payPeriods: payPeriods.reducer.bind(payPeriods),
    paystubs: paystubs.reducer.bind(paystubs),
    rateSheets: rateSheets.reducer.bind(rateSheets)
})