import { MTimeBank, MTimeOffPolicy } from "store/types/models/time";
import { v1 as uuid } from 'uuid'

export type ConfigurationBank = Omit<
                            MTimeBank, 
                                '_id' 
                                | 'accruals' 
                                | 'requests' 
                                | 'policy'
                                | 'hoursPerUnit'
                            > & {
                                id: string, 
                                policy: Omit<
                                            MTimeOffPolicy, 
                                                '_id'
                                                | 'accrualAmount'
                                                | 'accrualMaximum'
                                            > & {
                                                accrualAmount: string | number, 
                                                accrualMaximum?: string | number
                                            }, 
                                displayIndex: number, 
                                open: boolean, 
                                hoursPerUnit: string | number,
                                templateTitle?: string,
                                error?: string
                            }

export interface ConfigureBanksFormState {
    [bankId: string]: ConfigurationBank
}

function DEFAULT_BANK(displayIndex: number, title?: string, personId?: string, timeCodeId?: string): ConfigurationBank {
    return {
        id: uuid(),
        displayIndex,
        title: title || '',
        personId: personId || '',
        unit: 'hour',
        hoursPerUnit: 1,
        timeCodeId: timeCodeId || '',
        policy: {
            accrualPeriod: 'never',
            accrualAmount: 0,
            effectiveDate: new Date().toISOString()
        },
        open: false
    }
}

export enum types {
    RESET = 'happin/forms/configureBanks/RESET',
    ADD = 'happin/forms/configureBanks/ADD_BANK',
    REMOVE = 'happin/forms/configureBanks/REMOVE_BANK',
    REORDER = 'happin/forms/configureBanks/REORDER',
    TITLE = 'happin/forms/configureBanks/TITLE',
    UNIT = 'happin/forms/configureBanks/SET_UNIT',
    HOURS_PER_UNIT = 'happin/forms/configureBanks/HOURS_PER_UNIT',
    PERIOD = 'happin/forms/configureBanks/PERIOD',
    AMOUNT = 'happin/forms/configureBanks/AMOUNT',
    DATES = 'happin/forms/configureBanks/DATES',
    WEEKS = 'happin/forms/configureBanks/WEEKS',
    MONTHS = 'happin/forms/configureBanks/MONTHS',
    WEEKDAYS = 'happin/forms/configureBanks/WEEKDAYS',
    STARTING_ON = 'happin/forms/configureBanks/STARTING_ON',
    MAXIMUM = 'happin/forms/configureBanks/MAXIMUM',
    TOGGLE = 'happin/forms/configureBanks/TOGGLE',
    EFFECTIVE_DATE = 'happin/forms/configureBanks/EFFECTIVE_DATE',
    AUTO_ACCRUAL = 'happin/forms/configureBanks/AUTO_ACCRUAL',
    TEMPLATE_TITLE = 'happin/forms/configureBanks/TEMPLATE_TITLE',
    ERROR = 'happin/forms/configureBanks/ERROR'
}


export type Actions =
 | {
     type: types.RESET, 
     payload: ConfigureBanksFormState 
    }
 | {
     type: types.ADD, 
     payload: {title: string, personId: string, timeCodeId: string} 
    }
| {
    type: types.REMOVE, 
    payload: {id: string} 
    }
| {
    type: types.REORDER, 
    payload: {id: string, newIndex: number} 
    }
 | {
     type: types.TITLE, 
     payload: {id: string, title: string} 
    }
 | {
     type: types.UNIT, 
     payload: {id: string, unit: string} 
    }
 | {
     type: types.HOURS_PER_UNIT, 
     payload: {id: string, hoursPerUnit: number | string} 
    }
 | {
     type: types.PERIOD, 
     payload: {id: string, accrualPeriod: MTimeOffPolicy['accrualPeriod']} 
    }
 | {
     type: types.AMOUNT, 
     payload: {id: string, accrualAmount: number | string} 
    }
 | {
     type: types.DATES, 
     payload: {id: string, accrueDates: MTimeOffPolicy['accrueDates']} 
    }
 | {
     type: types.WEEKS, 
     payload: {id: string, accrueWeeks: MTimeOffPolicy['accrueWeeks']} 
    }
 | {
     type: types.MONTHS, 
     payload: {id: string, accrueMonths: MTimeOffPolicy['accrueMonths']} 
    }
 | {
     type: types.WEEKDAYS, 
     payload: {id: string, accrueWeekdays: MTimeOffPolicy['accrueWeekdays']} 
    }
 | {
     type: types.STARTING_ON, 
     payload: {id: string, startingOn: string} 
    }
 | {
     type: types.MAXIMUM, 
     payload: { id: string, accrualMaximum: number | string | undefined } 
    }
| {
    type: types.TOGGLE, 
    payload: {id: string} 
    }
| {
    type: types.EFFECTIVE_DATE, 
    payload: {id: string, effectiveDate: string}
    }
| {
    type: types.AUTO_ACCRUAL, 
    payload: {id: string, autoAccrual?: boolean}
    }
| {
    type: types.TEMPLATE_TITLE, 
    payload: {id: string, templateTitle?: string}
    }
| {
    type: types.ERROR, 
    payload: {id: string, error?: string}
    }
export function reducer(state: ConfigureBanksFormState={}, action: Actions): ConfigureBanksFormState {
    if (action.type === types.RESET) return action.payload
    
    if (action.type === types.ADD) {
        const newIndex = Object.values(state).length
        const newBank = DEFAULT_BANK(newIndex, action.payload.title, action.payload.personId, action.payload.timeCodeId);
        return {
            ...state,
            [newBank.id]: newBank
        }
    }
    const existingState = state[action.payload?.id]
    if (!existingState) return state
    switch (action.type) {
        case types.REMOVE: {
            const {[action.payload.id]: _redacted, ...newState} = state;
            return newState
        }
        case types.REORDER: {
            const previousIndex = existingState.displayIndex;
            const indexChange = action.payload.newIndex - previousIndex;
            console.log('index change', indexChange, 'from', previousIndex)
            const banks = Object.values(state);
            const newState = banks.map( bank => {
                        if (bank.id === action.payload.id) {
                            return {
                                ...bank,
                                displayIndex: action.payload.newIndex
                            }
                        } else if (indexChange < 0 && bank.displayIndex >= action.payload.newIndex && bank.displayIndex < previousIndex ) {
                            return {
                                ...bank,
                                displayIndex: bank.displayIndex + 1
                            }
                        } else if (indexChange > 0 && bank.displayIndex <= action.payload.newIndex && bank.displayIndex > previousIndex ) {
                            return {
                                ...bank,
                                displayIndex: bank.displayIndex - 1
                            }
                        } else {
                            return bank
                        }
                    })
                    .reduce( (newState, bank) => ({...newState, [bank.id]: bank}), {} as Record<string, ConfigurationBank>)

            return newState
        }
        case types.TITLE: {
            return {
                ...state,
                [action.payload.id]: {
                    ...existingState,
                    title: action.payload.title
                }
            }
        }
        case types.UNIT: {
            return {
                ...state,
                [action.payload.id]: {
                    ...existingState,
                    unit: action.payload.unit
                }
            }
        }
        case types.HOURS_PER_UNIT: {
            return {
                ...state,
                [action.payload.id]: {
                    ...existingState,
                    hoursPerUnit: action.payload.hoursPerUnit
                }
            }
        }
        case types.PERIOD: {
            return {
                ...state,
                [action.payload.id]: {
                    ...existingState,
                    policy: {
                        ...existingState.policy,
                        accrualPeriod: action.payload.accrualPeriod
                    }
                }
            }
        }
        case types.AMOUNT: {
            return {
                ...state,
                [action.payload.id]: {
                    ...existingState,
                    policy: {
                        ...existingState.policy,
                        accrualAmount: action.payload.accrualAmount
                    }
                }
            }
        }
        case types.DATES: {
            return {
                ...state,
                [action.payload.id]: {
                    ...existingState,
                    policy: {
                        ...existingState.policy,
                        accrueDates: action.payload.accrueDates
                    }
                }
            }
        }
        case types.WEEKS: {
            return {
                ...state,
                [action.payload.id]: {
                    ...existingState,
                    policy: {
                        ...existingState.policy,
                        accrueWeeks: action.payload.accrueWeeks
                    }
                }
            }
        }
        case types.MONTHS: {
            return {
                ...state,
                [action.payload.id]: {
                    ...existingState,
                    policy: {
                        ...existingState.policy,
                        accrueMonths: action.payload.accrueMonths
                    }
                }
            }
        }
        case types.WEEKDAYS: {
            return {
                ...state,
                [action.payload.id]: {
                    ...existingState,
                    policy: {
                        ...existingState.policy,
                        accrueWeekdays: action.payload.accrueWeekdays
                    }
                }
            }
        }
        case types.STARTING_ON: {
            return {
                ...state,
                [action.payload.id]: {
                    ...existingState,
                    policy: {
                        ...existingState.policy,
                        startingOn: action.payload.startingOn
                    }
                }
            }
        }
        case types.MAXIMUM: {
            return {
                ...state,
                [action.payload.id]: {
                    ...existingState,
                    policy: {
                        ...existingState.policy,
                        accrualMaximum: action.payload.accrualMaximum
                    }
                }
            }
        }
        case types.EFFECTIVE_DATE: {
            return {
                ...state,
                [action.payload.id]: {
                    ...existingState,
                    policy: {
                        ...existingState.policy,
                        effectiveDate: action.payload.effectiveDate
                    }
                }
            }
        }
        case types.AUTO_ACCRUAL: {
            return {
                ...state,
                [action.payload.id]: {
                    ...existingState,
                    policy: {
                        ...existingState.policy,
                        autoAccrual: action.payload.autoAccrual
                    }
                }
            }
        }
        case types.TOGGLE: {
            return {
                ...state,
                [action.payload.id]: {
                    ...existingState,
                    open: !existingState.open
                }
            }
        }
        case types.ERROR: {
            return {
                ...state,
                [action.payload.id]: {
                    ...existingState,
                    error: action.payload.error
                }
            }
        }
        default: return state
    }
}