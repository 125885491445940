////// Packages //////////////////
import React, {  useEffect, useState, FC } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink as BootstrapNavLink } from 'reactstrap';

////// Local Components //////////
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';

////// Global Components /////////
import { RouteDef } from 'routes/routes'
import { Location } from 'history'

////// CommonJS Helpers //////////
import { settings } from 'store/ducks/settings';

////// Custom Hooks //////////////

////// Thunks ////////////////////



interface Props {
  routes: RouteDef[];
  location: Location;
}

const NavbarVerticalMenu: FC<Props> = ({ routes, location }) => {
  const [openedIndex, setOpenedIndex] = useState(null as number | null);
  const dispatch = useDispatch();
  
  useEffect(() => {
    let openedDropdown = null;
    routes.forEach((route, index) => {
      if (location.pathname.indexOf(route.to) === 0) openedDropdown = index;
    });

    setOpenedIndex(openedDropdown);
    // eslint-disable-next-line
  }, []);

  const toggleOpened = (event: React.MouseEvent<HTMLAnchorElement>, index: number) => {
    event.preventDefault();
    return setOpenedIndex(openedIndex === index ? null : index);
  };

  return (
    <>
      {routes.map((route, index) => {
        if (!route.children) {
          return (
            <NavItem key={index}>
              <NavLink className="nav-link" {...route} onClick={() => dispatch(settings.actions.setShowBurgerMenu(false))}>
                <NavbarVerticalMenuItem route={route} />
              </NavLink>
            </NavItem>
          )
        }
        return (
          <NavItem key={index}>
            <BootstrapNavLink
              onClick={e => toggleOpened(e, index)}
              className="dropdown-indicator cursor-pointer"
              aria-expanded={openedIndex === index}
            >
              <NavbarVerticalMenuItem route={route} />
            </BootstrapNavLink>
            <Collapse isOpen={openedIndex === index}>
              <Nav>
                <NavbarVerticalMenu routes={route.children} location={location} />
              </Nav>
            </Collapse>
          </NavItem>
        );
      })}
    </>
  )
};

export default withRouter<any, any>(NavbarVerticalMenu);
