export enum types {
    SET = 'happin/deduplicator/SET',
    CLEAR = 'happin/deduplicator/CLEAR',
    RESET = 'happin/deduplicator/RESET'
}

interface SetAction {
    type: types.SET,
    payload: {
        path: (string | number)[];
        expiration: number;
    }
}
interface ClearAction {
    type: types.CLEAR,
    payload: (string | number)[];
}

interface ResetAction {
    type: types.RESET
}

interface NoAction {
    type: ''
}
type DeduplicatorAction = SetAction | ClearAction | ResetAction | NoAction;

export function reducer(state = {} as Record<string|number, any>, action = {} as DeduplicatorAction) {
    switch(action.type) {
        case types.SET: {
            const obj = {...state};
            let recursor = obj;
            for (const i in action.payload.path) {
                const key = action.payload.path[i];
                if (+i < action.payload.path.length - 1) {
                    recursor[key] = {...recursor[key]}
                    recursor = recursor[key]
                } else {
                    recursor[key] = action.payload.expiration;
                }
            }
            return obj;
        }

        case types.CLEAR: {
            const obj = {...state};
            let recursor = obj;
            for (const i in action.payload) {
                const key = action.payload[i];
                if (+i < action.payload.length - 1) {
                    recursor[key] = {...recursor[key]}
                    recursor = recursor[key]
                } else {
                    delete recursor[key];
                }
            }
            return obj;
        }

        case types.RESET: return {};

        default: return state;
    }
}

function set(path: (string | number)[], expiration: number): SetAction | NoAction {
    if (!Array.isArray(path)) return {type: ''}
    return {
        type: types.SET,
        payload: {
            path,
            expiration
        }
    }
}

function clear(path: (string|number)[]): ClearAction | NoAction {
    if (!Array.isArray(path)) return {type: ''}
    return {
        type: types.CLEAR,
        payload: path
    }
}

function reset(): ResetAction {
    return {
        type: types.RESET
    }
}

export const actions = {
    set,
    clear,
    reset
}
const deduplicator = {
    types,
    reducer,
    actions
}

export default deduplicator;