////// Packages //////////////////
import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

////// Local Components //////////

////// Global Components /////////
import Flex from "./Flex";
import Image from "./Image";
import ProgressiveImage from "./ProgressiveImage";

////// CommonJS Helpers //////////

////// Custom Hooks //////////////

////// Thunks ////////////////////

export type AvatarSize = "s" | "m" | "l" | "xl" | "2xl" | "3xl" | "4xl" | "5xl";

export interface AvatarProps {
  size?: AvatarSize;
  rounded?: string;
  src?: string | string[];
  name?: string;
  emoji?: string;
  className?: string;
  mediaClass?: string;
  isExact?: boolean;
  icon?: IconProp;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const Avatar: FC<AvatarProps> = ({
  size = "xl",
  rounded = "circle",
  src,
  name,
  emoji,
  className,
  mediaClass,
  isExact = false,
  icon,
  onClick
}) => {
  const avatarClassNames = ["avatar", `avatar-${size}`, className].join(" ");
  const mediaClasses = [
    rounded ? `rounded-${rounded}` : "rounded",
    mediaClass
  ].join(" ");
  const sizeMap: { [key: string]: "s" | "m" | "l" | "original" } = {
    s: "s",
    m: "s",
    l: "s",
    xl: "m",
    "2xl": "m",
    "3xl": "m",
    "4xl": "l",
    "5xl": "l"
  };

  const image = (src: string, className?: string) => {
    if (sizeMap[size] === "l") {
      return (
        <ProgressiveImage
          className={className}
          src={src}
          size={sizeMap[size] || "m"}
          alt=""
        />
      );
    }
    return (
      <Image
        className={className}
        src={src}
        size={sizeMap[size] || "m"}
        alt=""
        width=""
        height=""
        placeholderClass={[avatarClassNames, 'bg-dark', 'border', 'rounded-circle'].join(' ')}
      />
    );
  };

  const singleImage = (src: string) => image(src, mediaClasses);

  const doubleImage = (src: string[]) => (
    <div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
      <div className="w-50 border-right">{image(src[0])}</div>
      <div className="w-50">{image(src[1])}</div>
    </div>
  );

  const tripleImage = (src: string[]) => (
    <div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
      <div className="w-50 border-right">{image(src[0])}</div>
      <div className="w-50 d-flex flex-column">
        {image(src[1], "h-50 border-bottom")}
        {image(src[2], "h-50")}
      </div>
    </div>
  );

  const getAvatar = () => {
    if (src) {
      if (Array.isArray(src)) {
        switch (src.length) {
          case 0:
            break;
          case 1:
            if (!src[0]) break;
            return singleImage(src[0]);
          case 2:
            return doubleImage(src);
          default:
            return tripleImage(src);
        }
      } else {
        return singleImage(src);
      }
    }

    if (name) {
      return (
        <div className={`avatar-name ${mediaClasses}`}>
          <span>
            {isExact ? name : name.match(/\b\w/g)?.slice(0, 4).join("")}
          </span>
        </div>
      );
    }

    if (icon) {
      return (
        <Flex className={`avatar-name ${mediaClasses} flex-center`}>
          <FontAwesomeIcon icon={icon} />
        </Flex>
      );
    }

    if (emoji) {
      return (
        <div className={`avatar-emoji ${mediaClasses}`}>
          <span role="img" aria-label="Emoji">
            {emoji}
          </span>
        </div>
      );
    }
    return (
      <Flex className={`avatar-name ${mediaClasses} flex-center`}>
        <FontAwesomeIcon icon="user" />
      </Flex>
    );
  };

  return <div onClick={onClick} className={avatarClassNames}>{getAvatar()}</div>;
};

export default Avatar;
