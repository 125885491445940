import { createStore, combineReducers, applyMiddleware, compose, AnyAction } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import thunk from 'redux-thunk'
import { reducer as login } from './ducks/login'
import { idReducer } from './ducks/id'
import { formReducer } from './ducks/forms/forms'
import { stackReducer } from './ducks/stack'
import { settings } from './ducks/settings'
import { reducer as deduplicator } from './ducks/deduplicator'
import { reducer as ws } from './ducks/ws';
import { reducer as options } from './ducks/options';
import { reducer as time } from './ducks/time';
import { reducer as finance } from './ducks/finance';
import { managementReducer as management } from './ducks/management';

const appConfig = {
  key: 'app',
  storage,
  blacklist: ['settings']
}

const settingsConfig = {
  key: 'settings',
  storage
}

const app = combineReducers({
  login,
  forms: formReducer,
  settings: persistReducer(settingsConfig, settings.reducer.bind(settings)),
  options
})

const appReducer = combineReducers({
  app: persistReducer(appConfig, app),
  id: idReducer,
  time,
  finance,
  management,
  stack: stackReducer,
  deduplicator,
  ws
})


const rootReducer = (state: any, action: AnyAction) => {
  if (action?.type === 'RESET') {
    storage.removeItem('persist:app');
    // state = undefined;
    const {options: _redacted, ...keptSettings} = state.app.settings
    state = {app: {
        login: {loggedIn: false},
        settings: keptSettings
      }
    }
  }
  return appReducer(state, action);
}

export const store = createStore(
  rootReducer,
  undefined,
  compose(
    applyMiddleware(
      thunk
    ),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
)


export const persistor = persistStore(store);
