import { AnyAction } from "redux"
import { WsMessage } from "store/types/interface/common"

export const types = {
    OPEN: 'happin/app/WS/OPEN',
    CLOSE: 'happin/app/WS/CLOSE',
    MESSAGE: 'happin/app/WS/MESSAGE',
    ERROR: 'happin/app/WS/ERROR'
}

const DEFAULT_STATE = {
    status: 'closed',
    errorMessage: '',
    messageLog: [] as WsMessage[]
}

export const reducer = (state = DEFAULT_STATE, action = {} as AnyAction) => {
    if (!action) return state;
    const {payload, type} = action;

    switch(type) {
        case types.OPEN:
            return {...state, status: 'open'}
        case types.CLOSE:
            return {...state, status: 'closed'}
        case types.MESSAGE:
            return {
                ...state,
                messageLog: state.messageLog.slice(-49).concat([payload])
            }
        case types.ERROR:
            return {...state, status: 'error', errorMessage: payload}
        default:
            return state;
    }
}