import React, {FC} from 'react';
import classNames from 'classnames';
import { Container } from 'reactstrap';
import Background, {Props as BackgroundProps} from './Background';

interface Props extends BackgroundProps {
  fluid?: boolean;
  bg?: string;
  bgClassName?: string;
  className?: string;
  children: React.ReactNode;
}

const Section: FC<Props> = ({ fluid, bg, image, overlay, position, video, bgClassName, className, children, ...rest }) => {

  return (
    <section className={classNames({ [`bg-${bg}`]: bg }, className)} {...rest}>
      {image && <Background className={bgClassName} image={image} overlay={overlay} position={position} video={video} />}
      <Container fluid={fluid}>{children}</Container>
    </section>
  );
};



export default Section;
