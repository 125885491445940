import { combineReducers } from "redux";
import { reducer as addBoard } from './addBoard';
import { reducer as configureBanks } from './configureBanks'
import { reducer as myBoards } from './myBoards'
import { reducer as geoSearch } from './geoSearch';
import { reducer as productivityStats } from './productivityStats';
import { BaseDuck } from "../BaseDuck";
import { MContract } from "store/types/models/finance";
import { Populate } from "store/types/models/common";
import { DropzoneFile } from "store/store";

export const newContract = new BaseDuck<Partial<Populate<MContract, 'documents', DropzoneFile[]>>>('newContract')
newContract.replaceKeys = ['documents'];
newContract.DEFAULT_STATE = {asdfjh923y7248379y4: {_id: 'asdfjh923y7248379y4'}}

export const formReducer = combineReducers({
    addBoard,
    configureBanks,
    myBoards,
    geoSearch,
    productivityStats,
    newContract: newContract.reducer.bind(newContract)
})