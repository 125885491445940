import { useDispatch, useSelector, DefaultRootState } from  'react-redux';
import useAction from './useAction';
import { useCallback, useEffect, useMemo } from 'react';
import deduplicator from 'store/thunks/deduplicate';
import { ActionCreator, WsMessage } from 'store/types/interface/common';

export interface UseLoadAndSelectReturn<T> {
    loading: boolean;
    error?: any;
    value?: T | null;
    force: ()=>void;
}

interface UseLoadAndSelectParams<T> {
    actionCreator?: ActionCreator<any> ;
    selector: (state: DefaultRootState)=>( T | null | undefined );
    equality?: (left: any, right: any)=>boolean;
    postParams?: any[] | any;
    deduplicate?: (string | number)[];
    timeout?: number;
    onLoad?: (response: WsMessage | null)=>void;
    expiration?: number;
}

export type Loader = <T>( params: UseLoadAndSelectParams<T> ) => UseLoadAndSelectReturn<T>

function useLoadAndSelect<T>({actionCreator, selector, equality, postParams, deduplicate, timeout = undefined, onLoad, expiration = 300000}: UseLoadAndSelectParams<T>): UseLoadAndSelectReturn<T> {
    const dispatch = useDispatch();
    const {loading, error, post} = useAction(actionCreator, timeout);
    const selected = useSelector( selector, equality );
    
    const load = useCallback(() => {
        if ( actionCreator ) {
            const params = Array.isArray(postParams) ? postParams : [postParams]
            post(...params).then( response => onLoad && onLoad(response))
        }
    }, [actionCreator, post, postParams])

    const send = useCallback( (forcePull: boolean) => {
        dispatch( deduplicator( deduplicate || [], load, expiration, forcePull ) )
    }, [load, dispatch, deduplicate])

    const force = useCallback(() => {
        send(true)
    }, [send])

    useEffect(() => {
        send(false)
    }, [send])

    const loader: UseLoadAndSelectReturn<T> = useMemo(() => ({
        loading: loading,
        error: error,
        value: selected,
        force
    }), [loading, error, selected, force])

    return loader;
}

export default useLoadAndSelect;