////// Packages //////////////////
import { useState } from 'react';

////// Local Components //////////

////// Global Components /////////

////// CommonJS Helpers //////////
import { $api } from 'environ';

////// Custom Hooks //////////////

////// Thunks ////////////////////





const useFetch = (timeout = 3000, options: Parameters<typeof fetch>[1] = {}) => {
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        const post = async (data: any, path = $api) => {
            setLoading(true);
            const errTimeout = setTimeout( () => {
                setLoading( false ); 
                setError( 'Request Timed Out' );
            }, timeout )
            const response = await fetch(path, {
                method: 'POST',
                body: JSON.stringify(data),
                ...options
            }) ;
            const result = await response.json()
            setLoading(false);
            clearTimeout(errTimeout);
            if (!result?.success) {
                if (result?.error) {
                    setError(result.error)
                } else {
                    setError('Something went wrong')
                }
            }
            return result;
        }

        const get = async (path = $api) => {
            setLoading(true);
            const errTimeout = setTimeout( () => {
                setLoading( false ); 
                setError( 'Request Timed Out' );
            }, timeout )
            const response = await fetch(path, {
                method: 'GET',
                ...options
            }) ;
            const result = await response.json()
            setLoading(false);
            clearTimeout(errTimeout);
            if (!result?.success) {
                if (result?.error) {
                    setError(result.error)
                } else {
                    setError('Something went wrong')
                }
            }
            return result;
        }
        
        return {
            loading,
            error,
            post,
            get
        }

}


export default useFetch;