////// Packages //////////////////
import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

////// Local Components //////////

////// Global Components /////////
import Avatar, {AvatarProps} from '../components/Avatar';

////// CommonJS Helpers //////////

////// Custom Hooks //////////////

////// Thunks ////////////////////

interface Props {
  to?: string;
  avatar: AvatarProps;
  time: string;
  className?: string;
  unread?: boolean;
  flush?: boolean;
  emoji?: React.ReactNode;

}
const Notification: FC<Props> = ({ to, avatar, time, className, unread, flush, emoji, children }) => (
  <Link className={classNames('notification', { 'bg-200': unread, 'notification-flush': flush }, className)} to={to || "#"}>
    {avatar && (
      <div className="notification-avatar">
        <Avatar {...avatar} className="mr-3" />
      </div>
    )}
    <div className="notification-body">
      <p className={emoji ? 'mb-1' : 'mb-0'} >
        { children }
      </p>
      <span className="notification-time">
        {emoji && (
          <span className="mr-1" role="img" aria-label="Emoji">
            {emoji}
          </span>
        )}
        {time}
      </span>
    </div>
  </Link>
);


export default Notification;
