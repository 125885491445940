import { intersection, isEqual, union } from 'lodash';
import { getAdminUserInfo, getUserInfo } from 'store/thunks/users';
import permissions from 'store/permissions';
import useLoadAndSelect from 'store/hooks/useLoadAndSelect';
import { MUser } from 'store/types/models/id';
import { useSelector } from 'react-redux';


const useUser = (userId: string) => {
    const adminPermissions = [permissions.GLOBAL_ADMIN, permissions.CREATE_NEW_USERS];
    const isAdmin = useSelector( state => {
        const myUserId = state.app.login.userId;
        const myUser = state.id.users[myUserId || ''];
        const myGroup = state.id.usergroups[myUser?.groupId || '']
        const myPermissions = union(myUser?.explicitPermissions, myGroup?.permissions)
        return intersection(myPermissions, adminPermissions).length > 0
    })

    const user = useLoadAndSelect<MUser>({
        actionCreator: isAdmin ? getAdminUserInfo : getUserInfo,
        selector(state) { 
            return state.id.users[userId]
        },
        equality: isEqual,
        postParams: userId,
        deduplicate: [isAdmin ? 'adminUserInfo' : 'userInfo', userId]
    })

    return user;
}

export default useUser;