import { AnyAction } from "redux"
import { LoginState } from "store/types/interface/common"

export const types = {
    SET_LOGIN: 'happin/login/SET_LOGIN',
    ON_LOGIN: 'happin/login/ON_LOGIN'
}

const DEFAULT_STATE: LoginState = {
    loggedIn: false,
    userId: '',
    personId: '',
    organizationId: ''
}

export function reducer(state=DEFAULT_STATE, action: AnyAction = {type: 'none'}) {

    switch(action.type) {

        case types.SET_LOGIN: { 
            return {...state, loggedIn: action.payload}
        }

        case types.ON_LOGIN: {
            return {loggedIn: state.loggedIn, ...action.payload}
        }

        default:
            return state;

    }

}

function onLogin(userId, personId, organizationId) {
    return {
        type: types.ON_LOGIN,
        payload: {
            userId,
            personId,
            organizationId
        }
    }
}

function setLogin(isLoggedIn) {
    return {
        type: types.SET_LOGIN,
        payload: isLoggedIn
    }
}

export const actions = {
    onLogin,
    setLogin
}

const login = {
    types,
    reducer,
    actions
}

export default login;