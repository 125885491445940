import React, { FC } from "react";
import { Col, CardHeader, Row } from "reactstrap";
import classNames from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FalconColors } from "./falconTypes";
import Flex from "./Flex";

interface Props {
  title: React.ReactNode;
  light?: boolean;
  titleTag?: string | React.FC | React.DOMElement<any, any> | any;
  titleClass?: string;
  className?: string;
  breakPoint?: "sm" | "md" | "lg" | "xl";
  children?: React.ReactNode;
  icon?: IconProp;
  iconColor?: FalconColors;
  iconClass?: string;
  iconTransform?: string;
}

const FalconCardHeader: FC<Props> = ({
  title,
  light = true,
  titleTag: TitleTag = "h5",
  titleClass,
  className,
  breakPoint,
  children,
  icon,
  iconColor,
  iconTransform,
  iconClass
}) => (
  <CardHeader className={classNames({ "bg-light": light }, className)}>
    {children ? (
      <Row className="align-items-center">
        <Col>
          <TitleTag
            className={classNames(
              {
                "mb-0": !breakPoint,
                [`mb-${breakPoint}-0`]: !!breakPoint
              },
              titleClass
            )}
          >
            {icon && <FontAwesomeIcon icon={icon} className={'mr-2 text-' + iconColor + " " + iconClass} transform={iconTransform}/>}
            
            {title}
          </TitleTag>
        </Col>
        <Col
          className={"text" + (breakPoint ? "-" + breakPoint : "") + "-right"}
          {...{ [breakPoint ? breakPoint : "xs"]: "auto" }}
        >
          {children}
        </Col>
      </Row>
    ) : (
      <TitleTag
        className={classNames(
          {
            "mb-0": !breakPoint,
            [`mb-${breakPoint}-0`]: !!breakPoint
          },
          titleClass
        )}
      >
        <Flex align='center' justify='start'>
          {icon && <FontAwesomeIcon icon={icon} className={'mr-2 text-' + iconColor + ' ' + iconClass} transform={iconTransform}/>}
          {title}
        </Flex>
        
      </TitleTag>
    )}
  </CardHeader>
);

export default FalconCardHeader;
