////// Packages //////////////////
import React, { FC } from 'react'
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Card, UncontrolledTooltip } from 'reactstrap';

////// Local Components //////////

////// Global Components /////////

////// CommonJS Helpers //////////
import { AvatarSize } from 'components/Avatar';
import Flex from 'components/Flex';

////// Custom Hooks //////////////
import useAction from 'store/hooks/useAction';
import useMyUser from 'store/hooks/user/useMyUser';

////// Thunks ////////////////////
import { unpinAssignment } from 'store/thunks/users';
import PinIcon from 'components/icon/PinIcon';
import { toast } from 'react-toastify';

////// Types /////////////////////

interface Props {
    at?: 'navbar-vertical' | 'navbar-top' | 'auth';
    className?: string;
    horiz?: boolean;
    pinStrokeRGB?: {r: number; g: number; b: number;};
    size?: AvatarSize;
}

const PinnedAssignment: FC<Props> = ({ at='auth', size="m", className, horiz=true, pinStrokeRGB, ...rest }) => {
  const { value: user, ...userLoader } = useMyUser();
  const unpinAction = useAction(unpinAssignment);

  const pinnedAssignment = user?.pins?.assignment

  const handleUnpin = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const data = await unpinAction.post()
    if (data?.success) {
      toast.warning('Assignment unpinned')
    } else {
      toast.error(data?.error || 'Something went wrong')
    }
  }
 
    return (
      <>
      {pinnedAssignment &&
        <Card className="border bg-light rounded p-1 mr-4" id="pinned-assignment-tooltip">
            <Flex justify="between" align='center'>
              <Link
                to={`/stacks/details/${pinnedAssignment.stackId}/board/${pinnedAssignment.boardId}/task/${pinnedAssignment.taskId}/assignment/${pinnedAssignment._id}`}
                className={classNames(
                  'text-decoration-none ',
                  { 'navbar-brand text-left': at === 'navbar-vertical' },
                  { 'navbar-brand text-left': at === 'navbar-top' }
                )}
                {...rest}
              >
                <PinIcon size="s" onClick={handleUnpin}/>
              </Link>
              <Link className='text-decoration-none' to={`/stacks/${pinnedAssignment.stackId}/board/${pinnedAssignment.boardId}/task/${pinnedAssignment.taskId}/assignment/${pinnedAssignment._id}`}>
                <div className="border-left pl-2">
                  <h6 className="m-0 text-truncate">{pinnedAssignment.boardTitle}</h6>
                  <p className="font-weight-normal text-truncate text-left fs--1 m-0">{pinnedAssignment.taskTitle}</p>
                </div>
              </Link>
              
              
            </Flex>
            <UncontrolledTooltip 
              placement='bottom' 
              target="pinned-assignment-tooltip"
            >
                 {`Assignment: ${pinnedAssignment.description}`}
            </UncontrolledTooltip>
          
        </Card>}
        </>
    )
}

export default PinnedAssignment;

