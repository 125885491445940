////// Packages //////////////////
import {FC} from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

////// Local Components //////////

////// Global Components /////////

////// CommonJS Helpers //////////

////// Custom Hooks //////////////

////// Thunks ////////////////////

//////// Assets ////////
import happin_logo from 'assets/img/logos/happin-logo-06.svg'

interface Props {
  at?: 'navbar-vertical' | 'navbar-top' | 'auth';
  width: number;
  className?: string;
  horiz?: boolean;
  pinStrokeRGB?: {r: number; g: number; b: number;};
}

const Logo: FC<Props> = ({ at, width, className, horiz, pinStrokeRGB, ...rest }) => {
  return (
    <Link
      to="/home"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <img src={happin_logo} width={width} />
    </Link>
  );
};

Logo.defaultProps = { at: 'auth', width: 30, horiz: true };

export default Logo;
