import produce from "immer";
import { merge } from "lodash";
import { v4 as uuid } from 'uuid'
import { toast } from "react-toastify";
import { DropzoneFile } from "store/store";
import { 
    MMilestone, 
    MTask, 
} from "store/types/models/stack";
import { $PersonListItem, MOrganization } from "store/types/models/id";
import { OrganizationListItem } from "compositions/selectors/OrganizationPicker";

export type FormMember = $PersonListItem & {value: $PersonListItem['_id']; label: string; personId: $PersonListItem['_id']; role: string}
export type FormOrganization = OrganizationListItem & {value: MOrganization['_id']; label: MOrganization['name']; organizationId: MOrganization['_id']; role: string; members: FormMember[] }
export interface FormState {
    stackId: string; // this needs to be gotten from caller
    metaData: {
        type: string; // for Access this is Project, Pursuit, Potential
                        // for KCPL this would be Prospective, Planned, Project, Construction, Retirement
        identifier: string; // needs to check with API if the entered name is available. Debounce it!
        title: string;
        description: string; // maybe??? quilleditor?
        internalBudget?: number; // displayed as money, stored as number
        externalBudget?: number; // displayed as money, stored as number
        active: boolean; // checkbox
        ownerId: string; // this should be a selection box of people?
        estCompletionDate?: string; // date entry -- calendar popup
    };
    tags: string[]; // react-select element with multiselection. Maybe would be cool to have different colors of the tags, just to look pretty
    location?: {
        coordinates?: [number, number],
        description?: string;
        city?: string;
        state?: string;
        zip?: string;
    }
    documents: Record<string, DropzoneFile>;
    organizations: FormOrganization[];
    milestones: {
        identifier:string;
        title: MMilestone['title'];
        startDate?: MMilestone['startDate'];
        endDate: MMilestone['endDate'];
        tasks: {
            identifier: string;
            title: MTask['title'];
            budgetHours: MTask['budgetHours'];
            startDate?: MTask['startDate'];
            endDate?: MTask['endDate'];
            description?: MTask['description'];
            isBillable?: MTask['isBillable'];
        }[]
    }[];
}

const DEFAULT_TASK = () => ({
    identifier: 'task-' + uuid(),
    title: ''
})
const DEFAULT_MILESTONE = () => ({
    identifier: 'milestone-' + uuid(), 
    title: '',
    tasks: [DEFAULT_TASK()]
})

export const DEFAULT_STATE: FormState = {
    stackId: '', // this needs to be gotten from caller
    metaData: {
        type: '', // for Access this is Project, Pursuit, Potential
                        // for KCPL this would be Prospective, Planned, Project, Construction, Retirement
        identifier: '', // needs to check with API if the entered name is available. Debounce it!
        title: '',
        description: '', // maybe??? quilleditor?
        internalBudget: undefined, // displayed as money, stored as number
        externalBudget: undefined, // displayed as money, stored as number
        active: false, // checkbox
        ownerId: '', // this should be a selection box of people?
        estCompletionDate: undefined // date entry -- calendar popup
    },
    location: {
        type: 'Point',
        properties: {
            description: '',
            city: '',
            state: '',
            zip: ''
        }
    },
    tags: [], // react-select element with multiselection. Maybe would be cool to have different colors of the tags, just to look pretty
    documents: {},
    organizations: [],
    milestones: [DEFAULT_MILESTONE()]
}

export type Types = 
    'RESET' |
    'TYPE' |
    'IDENTIFIER' |
    'TITLE' |
    'DESCRIPTION' |
    'EXTERNAL_BUDGET' |
    'INTERNAL_BUDGET' |
    'ACTIVE' |
    'OWNER' |
    'WORKFLOW_ID' |
    'EST_COMPLETION_DATE' |
    'TAGS' |
    'LOCATION' | 
    'SET_DOCUMENTS' |
    'ADD_DOCUMENT' |
    'REMOVE_DOCUMENT' |
    'UPSERT_ORGANIZATION' |
    'REMOVE_ORGANIZATION' |
    'UPSERT_MEMBER' |
    'REMOVE_MEMBER' |
    'INSERT_MILESTONE' |
    'UPSERT_MILESTONE' |
    'MOVE_MILESTONE' |
    'REMOVE_MILESTONE' |
    'INSERT_TASK' |
    'UPSERT_TASK' |
    'MOVE_TASK' |
    'REMOVE_TASK' |
    'UPSERT_ASSIGNMENT' |
    'REMOVE_ASSIGNMENT'


export const types = {
    RESET: 'happin/forms/addBoard/RESET',
    TYPE: 'happin/forms/addBoard/TYPE',
    IDENTIFIER: 'happin/forms/addBoard/IDENTIFIER',
    TITLE: 'happin/forms/addBoard/TITLE',
    DESCRIPTION: 'happin/forms/addBoard/DESCRIPTION',
    EXTERNAL_BUDGET: 'happin/forms/addBoard/EXTERNAL_BUDGET',
    INTERNAL_BUDGET: 'happin/forms/addBoard/INTERNAL_BUDGET',
    ACTIVE: 'happin/forms/addBoard/ACTIVE',
    OWNER: 'happin/forms/addBoard/OWNER',
    WORKFLOW_ID: 'happin/forms/addBoard/WORKFLOW_ID',
    EST_COMPLETION_DATE: 'happin/forms/addBoard/EST_COMPLETION_DATE',
    TAGS: 'happin/forms/addBoard/TAGS',
    LOCATION: 'happin/forms/addBoard/LOCATION',
    SET_DOCUMENTS: 'happin/forms/addBoard/SET_DOCUMENTS',
    ADD_DOCUMENT: 'happin/forms/addBoard/ADD_DOCUMENT',
    REMOVE_DOCUMENT: 'happin/forms/addBoard/REMOVE_DOCUMENT',
    UPSERT_ORGANIZATION: 'happin/forms/addBoard/UPSERT_ORGANIZATION',
    REMOVE_ORGANIZATION: 'happin/forms/addBoard/REMOVE_ORGANIZATION',
    UPSERT_MEMBER: 'happin/forms/addBoard/UPSERT_MEMBER',
    REMOVE_MEMBER: 'happin/forms/addBoard/REMOVE_MEMBER',
    INSERT_MILESTONE: 'happin/forms/addBoard/INSERT_MILESTONE',
    UPSERT_MILESTONE: 'happin/forms/addBoard/UPSERT_MILESTONE',
    MOVE_MILESTONE: 'happin/forms/addBoard/MOVE_MILESTONE',
    REMOVE_MILESTONE: 'happin/forms/addBoard/REMOVE_MILESTONE',
    INSERT_TASK: 'happin/forms/addBoard/INSERT_TASK',
    UPSERT_TASK: 'happin/forms/addBoard/UPSERT_TASK',
    MOVE_TASK: 'happin/forms/addBoard/MOVE_TASK',
    REMOVE_TASK: 'happin/forms/addBoard/REMOVE_TASK',
    UPSERT_ASSIGNMENT: 'happin/forms/addBoard/UPSERT_ASSIGNMENT',
    REMOVE_ASSIGNMENT: 'happin/forms/addBoard/REMOVE_ASSIGNMENT'
}

interface ValidAction { type: Types; payload: any; }

export function reducer (state = DEFAULT_STATE, action: ValidAction): FormState {
    return produce(state, draft => {

        const {type, payload} = action;

        switch(type) {
            
            case types.TYPE: 
                draft.metaData.type = payload;
                break;

            case types.IDENTIFIER:
                draft.metaData.identifier = payload;
                break;
            case types.TITLE: 
                draft.metaData.title = payload;
                break;

            case types.DESCRIPTION:
                draft.metaData.description = payload;
                break;

            case types.EXTERNAL_BUDGET: 
                draft.metaData.externalBudget = payload;
                break;

            case types.INTERNAL_BUDGET: 
                draft.metaData.internalBudget = payload;
                break;

            case types.EST_COMPLETION_DATE: 
                draft.metaData.estCompletionDate = payload;
                break;

            case types.TAGS: 
                draft.tags = payload;
                break;

            case types.LOCATION: 
                draft.location = payload;
                break;

            case types.ACTIVE: 
                draft.metaData.active = payload;
                break;

            case types.OWNER: 
                draft.metaData.ownerId = payload;
                break;

            case types.WORKFLOW_ID:
                draft.stackId = payload;
                break;

            case types.SET_DOCUMENTS:
                if (typeof payload !== 'object') break;
                draft.documents = payload;
                break;

            case types.ADD_DOCUMENT:
                merge( draft, {documents: payload} );
                break;

            case types.REMOVE_DOCUMENT:
                delete draft.documents[payload];
                break;

            case types.UPSERT_ORGANIZATION: {
                if ( typeof payload !== 'object' || !payload?._id ) {
                    toast.error('Invalid upsert organization. Aborted.');
                    break;
                }
                const matchingOrganization = draft.organizations.findIndex( organization => organization._id === payload._id )
                if (matchingOrganization !== -1) {
                     draft.organizations[matchingOrganization] = {...payload, organizationId: payload._id}
                } else {
                    draft.organizations.push({...payload, organizationId: payload._id})
                }  
                break;
            }
            case types.REMOVE_ORGANIZATION: 
                draft.organizations = draft.organizations.filter(organization => organization._id !== payload)
                break;

            case types.UPSERT_MEMBER: {
                if ( typeof payload !== 'object' || !payload?._id ) {
                    toast.error('Invalid upsert member. Aborted.');
                    break;
                }
                const selectedOrganization = draft.organizations.find(org => org?._id === payload.organizationId)
                if (selectedOrganization) {
                    selectedOrganization.members = selectedOrganization.members || [];
                    const matchingMember = selectedOrganization.members.findIndex( member => member.personId === payload._id );
                    const newMember = {...payload, personId: payload._id}
                    if (matchingMember !== -1) {
                        selectedOrganization.members[matchingMember] = newMember
                    } else {
                        selectedOrganization.members.push(newMember)
                    }
                }
                break;
            }
            case types.REMOVE_MEMBER: {
                for ( const organization of draft.organizations) {
                    if (!organization.members) continue;
                    for (const member of organization.members) {
                        if (member.personId === payload) {
                            organization.members = organization.members!.filter( member => member.personId !== payload)
                        }
                    }
                }
                break;
            }
            case types.INSERT_MILESTONE: {
                draft.milestones.splice( payload.index, 0, DEFAULT_MILESTONE() );
                break;
            }

            case types.UPSERT_MILESTONE: {
                const { index, ...rest } = payload;
                merge ( draft.milestones[payload.index], rest )
                break;
            }

            case types.MOVE_MILESTONE: {
                const toMove = draft.milestones.splice(payload.fromIndex, 1)
                const toIndex = payload.fromIndex < payload.toIndex ? payload.toIndex -1 : payload.toIndex
                draft.milestones.splice( toIndex, 0, toMove[0] )
                break;
            }

            case types.REMOVE_MILESTONE: 
                draft.milestones.splice( payload.index, 1 )
                if (draft.milestones.length === 0) draft.milestones.push(DEFAULT_MILESTONE())
                break;

            case types.INSERT_TASK: 
                draft.milestones[payload.milestoneId]
                    .tasks
                    ?.splice( payload.index, 0, DEFAULT_TASK() );
                break;

            case types.UPSERT_TASK: { 
                const {index, milestoneId, ...rest} = payload;
                merge ( draft.milestones[milestoneId]?.tasks![index], rest )
                break;
            }

            case types.MOVE_TASK: {
                const sourceTasks = draft.milestones[payload.fromMilestoneId].tasks!;
                const destinationTasks = draft.milestones[payload.toMilestoneId].tasks!;
                const toMove = sourceTasks.splice( payload.fromIndex, 1 );
                destinationTasks.splice( payload.toIndex, 0, toMove[0] );
                if (sourceTasks.length === 0) sourceTasks.push( DEFAULT_TASK() )
                break;
            }

            case types.REMOVE_TASK: {
                const tasks = draft.milestones[payload.milestoneId].tasks
                tasks.splice( payload.index, 1 );
                if (tasks.length === 0) tasks.push( DEFAULT_TASK() )
                break;
            }

            // case types.UPSERT_ASSIGNMENT:
            //     if ( typeof payload !== 'object' || !payload?.id ) {
            //         console.log('Invalid upsert assignment. Aborted.')
            //         break;
            //     }
            //     draft.assignments[payload.id] = payload
            //     break;

            // case types.REMOVE_ASSIGNMENT: 
            //     delete draft.assignments[payload]
            //     break;

            case types.RESET:
                return DEFAULT_STATE;

            default: 
                break;
        }
    })
}