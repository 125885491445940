import produce from "immer"
import { mergeWith } from "lodash"
import { AnyAction } from "redux"
import { SettingsOptions } from "store/store"
import { types as wsTypes } from './ws'

        
const DEFAULT_STATE: SettingsOptions = {
    board: {
        tags: [],
        memberRoles: [],
        types: [],
        identifiers: []
    },
    kanban: {
        categories: []
    },
    stack: {
        identifiers: []
    }
}

export function reducer(state: SettingsOptions = DEFAULT_STATE, action: AnyAction) {
    return produce(state, draft => {
        const {payload, type} = action
        if (type === wsTypes.MESSAGE)  {
            if (payload.records) {
                for (const record of payload.records) {
                    if (record?.settings?.options) {
                        console.log('merging', record.settings.options)
                        mergeWith(draft, record.settings.options, (_obj, src) => Array.isArray(src) ? src : undefined )
                    }
                }
            }
        }
    })
    
}
