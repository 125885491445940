import { IName, MOrganization, MPerson } from 'store/types/models/id';
import { Permission } from 'store/types/models/common';
import { ActionCreator } from 'store/types/interface/common';
import { MAssignment } from 'store/types/models/stack';
import { SortOrder } from 'store/types/interface/stack';


export const getAdminOrgEmployees: ActionCreator<['getAdminOrgEmployees']> = (organizationId: string, pageNumber: number, numberPerPage: number) => {
    const actions = [
        {
            name: 'getAdminOrgEmployees',
            data: {
                organizationId,
                pageNumber,
                numberPerPage
            }
        }
    ] as const;
    return {actions}
}

interface UserTableProps {
    organizationId: MOrganization['_id'];
    pageNumber: number;
    sizePerPage: number;
    searchText?: string;
    sorts?: {
        name: SortOrder;
        position: SortOrder;
        startDate: SortOrder;
        active: SortOrder;
    }
}

export const getUserTable: ActionCreator<['getUserTable']> = (userProps: UserTableProps) => {
    const actions = [
        {
            name: 'getUserTable',
            data: userProps
        }
    ] as const;
    return {actions}
}


export const getUserFromPerson: ActionCreator<["getUserFromPerson"]> = (personId: MPerson['_id']) => {
    const actions = [
        {
            name: 'getUserFromPerson',
            data: {personId}
        }
    ] as const;
    return { actions }
}

export const getAdminUserInfo: ActionCreator<["getAdminUserInfo"]> = (userId: string) => {
    const actions = [
        {
            name: 'getAdminUserInfo',
            data: {
                userId
            }
        }
    ] as const;
    return { actions }
}


export const getUserInfo: ActionCreator<["getUserInfo"]> = (userId: string) => {
    const actions = [
        {
            name: 'getUserInfo',
            data: {
                userId
            }
        }
    ] as const;
    return { actions }
}


export const addOrganizationUser: ActionCreator<["addOrganizationUser"]> = (firstName: string, lastName: string, email: string, position: string) => {
    const name = {
        first: firstName,
        last: lastName
    }
    const actions = [
        {
            name: 'addOrganizationUser',
            data: {
                name,
                email,
                position
            }
        }
    ] as const;
    return { actions }
}


export const setUserInfo: ActionCreator<["setUserInfo"]> = (name: IName, internalId, startDate: Date, position: string, email: string, mailingAddress, phoneNumber, userId: string) => {
    const actions = [
        {
            name: "setUserInfo",
            data: {
                userId,
                name,
                internalId,
                startDate,
                position,
                email,
                mailingAddress,
                phoneNumber
            }
        }
        //mailingAddress
        //phoneNumber
    ] as const;
    return { actions }
}


export const sendUserPermissions: ActionCreator<["setUserExplicitPermissions"]> = (explicitPermissions: Permission[], userId: string) => {
    const actions = [
        {
            name: 'setUserExplicitPermissions',
            data: {
                userId,
                explicitPermissions
            }
        }
    ] as const;
    return { actions }
}

export const activateUser: ActionCreator<["setOrgEmployeeActive"]> = (personId: string) => {
    const actions = [
        {
            name: 'setOrgEmployeeActive',
            data: {
                active: true,
                personId
            }
        }
    ] as const;
    return { actions }
}

export const deactivateUser: ActionCreator<["setOrgEmployeeActive"]> = (personId: string) => {
    const actions = [
        {
            name: 'setOrgEmployeeActive',
            data: {
                active: false,
                personId
            }
        }
    ] as const;
    return { actions }
}


export const removeOrganizationUser: ActionCreator<["setOrgEmployeeRemoved"]> = (personId: string) => {
    const actions = [
        {
            name: 'setOrgEmployeeRemoved',
            data: {
                personId,
                removed: true
            }
        }
    ] as const;
    return { actions }
}

export const setIntroWizardStep: ActionCreator<['setIntroWizardStep']> = (step?: number) => {
    const actions = [
        {
            name: 'setIntroWizardStep',
            data: { step }
        }
     ] as const;

    return { actions };
}

export const pinPerson: ActionCreator<['pinPerson']> = (personId: MPerson['_id']) => {
    const actions = [
        {
            name: 'pinPerson',
            data: { personId }
        }
     ] as const;

    return { actions };
}

export const pinAssignment: ActionCreator<['pinAssignment']> = (assignmentId: MAssignment['_id']) => {
    const actions = [
        {
            name: 'pinAssignment',
            data: { assignmentId }
        }
     ] as const;

    return { actions };
}

export const unpinPerson: ActionCreator<['unpinPerson']> = (personId: MPerson['_id']) => {
    const actions = [
        {
            name: 'unpinPerson',
            data: { personId }
        }
     ] as const;

    return { actions };
}

export const unpinAssignment: ActionCreator<['unpinAssignment']> = (assignmentId: MAssignment['_id']) => {
    const actions = [
        {
            name: 'unpinAssignment',
            data: { assignmentId }
        }
     ] as const;

    return { actions };
}

const thunks = {
    getAdminOrgEmployees,
    getUserTable,
    addOrganizationUser,
    setUserInfo,
    sendUserPermissions,
    activateUser,
    deactivateUser,
    removeOrganizationUser,
    pinPerson,
    unpinPerson,
    pinAssignment, 
    unpinAssignment
}

export default thunks