import { autoDropBreakpoint } from '../environ'

type AnyFunction<T extends any[]> = (...args: T)=>void

const autoDrop = <T extends any[]>( cb: AnyFunction<T> = () =>{} ) => {
    let timer: NodeJS.Timeout;
    return (openAction?: any, ...params: T) => () => {
        const windowWidth = window.innerWidth;
        if (windowWidth > autoDropBreakpoint) {
            if (openAction) {
                clearTimeout(timer);
                cb && cb(...params);
            } else {
                timer = setTimeout(() => {
                    cb && cb(...params)
                }, 200);
            }
        }
    }
}

export default autoDrop;