import { useSelector } from 'react-redux';
import useUser from './useUser';

const useMyUser = () => {
    const myUserId = useSelector( state => state.app.login.userId || '');
    const myUser = useUser(myUserId);

    return myUser;
}

export default useMyUser;