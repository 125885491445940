import React, { FC } from 'react';
import { Form, Input, InputProps } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchBox: FC<InputProps> = ({ type, placeholder, className, onSubmit, onKeyPress, ...props }) => {

  const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit && onSubmit(e)
    }
    onKeyPress && onKeyPress(e)
  }

  return (
    <Form inline className="search-box">
      <Input 
        placeholder="Search..." 
        aria-label="Search" 
        className={"rounded-pill search-input " + className} 
        onSubmit={ e => e.preventDefault() } 
        onKeyPress={ handleKeyPress } 
        {...props} 
      />
      <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
    </Form>
  )
};

export default SearchBox;
