////// Packages //////////////////
import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { Card, CardBody, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';

////// Local Components //////////

////// Global Components /////////

////// CommonJS Helpers //////////
import { breakpoints } from 'helpers/utils';
import { topNavbarBreakpoint } from '../../../environ';

////// Custom Hooks //////////////

////// Thunks ////////////////////

export interface NavbarDropdownProps {
  title: string;
  right?: boolean;
  children: React.ReactNode;
  dropdownConfig?: {
    class?: {
      card?: string;
      body?: string;
    };
    img?: {
      src?: string;
      width?: string;
    }

  }
}
const NavbarDropdown: FC<NavbarDropdownProps> = ({ title, right, children, dropdownConfig={} }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const {
    class: {
      card: dropdownCardClass = '',
      body: dropdownCardBodyClass = ''
    } = {},
    img: {
      src: dropdownCardHeaderImg = '',
      width: dropdownCardHeaderImgWidth = 130
    } = {}
  } = dropdownConfig

  return (
      <Dropdown
        nav
        inNavbar
        isOpen={dropdownOpen}
        toggle={() => setDropdownOpen(!dropdownOpen)}
        onMouseOver={() => {
          const windowWidth = window.innerWidth;
          if (windowWidth >= breakpoints[topNavbarBreakpoint]) {
            setDropdownOpen(true);
          }
        }}
        onMouseLeave={() => {
          const windowWidth = window.innerWidth;
          if (windowWidth >= breakpoints[topNavbarBreakpoint]) {
            setDropdownOpen(false);
          }
        }}
      >
        <DropdownToggle nav caret>
          { title }
        </DropdownToggle>
        { children && (
          <DropdownMenu right={ right } className="dropdown-menu-card mt-0">
              <Card className={ classNames( 'shadow-none max-h-dropdown', dropdownCardClass ) } >
                { dropdownCardHeaderImg && 
                  <img src={ dropdownCardHeaderImg } alt="" className="position-absolute b-0 r-0" width={ dropdownCardHeaderImgWidth } />
                }
                <CardBody className={ classNames( 'scrollbar', dropdownCardBodyClass ) }>
                  <div className="nav flex-column">
                    { children }
                  </div>
                </CardBody>
              </Card>
            </DropdownMenu>
          )
        }
      </Dropdown>
  );
};

export default NavbarDropdown;
