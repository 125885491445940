////// Packages //////////////////
import { useEffect, useRef, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Collapse, Nav, Navbar, UncontrolledTooltip } from 'reactstrap';
import is from 'is_js';
import classNames from 'classnames';

////// Local Components //////////
import NavbarVerticalMenu from './components/NavbarVerticalMenu';
import NavbarTopDropDownMenus from './components/NavbarTopDropDownMenus';

////// Global Components /////////
import Logo from 'compositions/Logo';
import Flex from 'components/Flex';

////// CommonJS Helpers //////////
import routes from 'routes/routes';
import { navbarBreakPoint, topNavbarBreakpoint } from 'environ';
import {settings} from 'store/ducks/settings';

////// Custom Hooks //////////////

////// Thunks ////////////////////

interface Props {
  navbarStyle?: 'dark' | 'vibrant' | 'card' | 'inverted' | 'transparent';
}

const NavbarVertical: FC<Props> = ({ navbarStyle="transparent" }) => {
  const dispatch = useDispatch();

  const showBurgerMenu = useSelector(state => state.app.settings.showBurgerMenu)
  const isNavbarVerticalCollapsed = useSelector(state => state.app.settings.isNavbarVerticalCollapsed)
  const isCombo = useSelector(state => state.app.settings.isVertical && state.app.settings.isTopNav)

  const navBarRef = useRef(null);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  //Control Component did mount and unmounted of hover effect
  if (isNavbarVerticalCollapsed) {
    HTMLClassList.add('navbar-vertical-collapsed');
  }

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time: NodeJS.Timeout | null = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };

  const getNavbarToggler = () => (
    <>
      <UncontrolledTooltip placement="left" target="toggleNavigationTooltip">
        Toggle Navigation
      </UncontrolledTooltip>
      <div className="toggle-icon-wrapper">
        <Button
          color="link"
          className="navbar-toggler-humburger-icon navbar-vertical-toggle"
          id="toggleNavigationTooltip"
          onClick={() => {
            document.getElementsByTagName('html')[0].classList.toggle('navbar-vertical-collapsed');
            dispatch(settings.actions.setVerticalCollapsed(!isNavbarVerticalCollapsed));
          }}
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </Button>
      </div>
    </>
  )

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical navbar-glass', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      light
    >
      <Flex align="center">
        {getNavbarToggler()}
        <Logo at="navbar-vertical" horiz width={120} />
      </Flex>

      <Collapse
        navbar
        isOpen={showBurgerMenu}
        className="scrollbar"
        innerRef={navBarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          if (time) clearTimeout(time);
          HTMLClassList.remove('navbar-vertical-collapsed-hover');
        }}
        style={
          (navbarStyle === 'vibrant' || undefined) && {
            backgroundImage: `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2))`
          }
        }
      >
        <Nav navbar vertical>
          <NavbarVerticalMenu routes={routes} />
        </Nav>
        <div className="settings px-3 px-xl-0">
          {isCombo && (
            <div className={`d-${topNavbarBreakpoint}-none`}>
              <div className="navbar-vertical-divider">
                <hr className="navbar-vertical-hr my-2" />
              </div>
              <Nav navbar>
                <NavbarTopDropDownMenus
                  setNavbarCollapsed={() => dispatch(settings.actions.setNavbarCollapsed(true))}
                  setShowBurgerMenu={() => dispatch(settings.actions.setShowBurgerMenu(true))}
                />
              </Nav>
            </div>
          )}
          <div className="navbar-vertical-divider">
            <hr className="navbar-vertical-hr my-2" />
          </div>
        </div>
      </Collapse>
    </Navbar>
  );
};


export default NavbarVertical;
