    
////// Packages //////////////////
import { AvatarSize } from 'components/Avatar'
import { FC } from 'react'

////// Local Components //////////

////// Global Components /////////

////// CommonJS Helpers //////////

////// Custom Hooks //////////////

////// Thunks ////////////////////

////// Types /////////////////////

interface Props {
    size: AvatarSize
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLDivElement>)=>void
}

const PinIcon: FC<Props> = ({size, className, onClick}) => (
        <div className={`${onClick ? 'cursor-pointer' : ''} mr-2 ml-1 avatar avatar-${size} ${className}`} onClick={onClick}>
    
            <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 16 16" >
            <g id="XMLID_1_">
                <path className={`pin-body ${onClick ? 'hover' : ''}`} d="M9.7,14.4C8.6,15.8,0.9,8.1,2.2,7c1.3-1.1,2.4-0.6,3-0.2C5.2,6.8,5.5,7,5.5,7c0,0,2.9-2.1,3.8-3.2
                c0.2-0.3-0.7-1.5,0-3.1c0-0.1,0.1-0.1,0.2-0.1c3.4,2.5,5.7,5.8,5.7,5.8c0,0.1-0.1,0.1-0.1,0.1c-0.3,0.2-0.8,0.4-1.7,0.3
                c-0.5-0.1-1,0-1,0c-0.8,0.5-2.4,2.9-2.7,3.8c-0.1,0.2-0.1,0.3,0,0.4C10.2,11.6,10.9,13.1,9.7,14.4z"/>
            </g>
            <path className={`pin-tip ${onClick ? 'hover' : ''}`} d="M5,13l-3.4,1.6l1.7-3.5C3.9,12.2,5,13,5,13z"/>
            </svg>
        </div>
)

export default PinIcon;
 