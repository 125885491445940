import { AnyAction } from "redux";
import { produce } from 'immer';
import { concat, mergeWith } from 'lodash';
import dayjs from "dayjs";
import { WritableDraft } from "immer/dist/internal";
import { mergeById } from "helpers/mergeById";
import {types} from './ws';

type DeepPartial<T> = T extends Record<any, any> 
    ?
    {[K in keyof T]?: DeepPartial<T[K]>}
    :
    T


export class BaseDuck<T> {
    public duckName: string;
    public DEFAULT_STATE: Record<string | number | symbol, T>;
    private basicTypes: {INSERT: string; UPSERT: string; REMOVE: string; RESET: string; FUNCTION: string;};

    constructor (duckName: string, DEFAULT_STATE: Record<string | number | symbol, T> = {}) {
        this.duckName = duckName;
        this.DEFAULT_STATE = DEFAULT_STATE;
        this.basicTypes = {
            INSERT: 'happin/' + duckName + '/INSERT',
            UPSERT: 'happin/' + duckName + '/UPSERT',
            REMOVE: 'happin/' + duckName + '/REMOVE',
            RESET: 'happin/' + duckName + '/RESET',
            FUNCTION: 'happin/' + duckName + '/FUNCTION'
        } 
        this.dateKeys = {};
    }

    public standardMerge = () => undefined;
    public replaceArrays = (objVal: any, srcVal: any) => Array.isArray(srcVal) ? srcVal : undefined;
    public concatArrays = (objVal: any, srcVal: any) => Array.isArray(srcVal) ? concat(objVal, srcVal) : undefined;
    public dateKeys: Partial<T>;
    public mergeDates = (objVal: any, srcVal: any) => (objVal && srcVal) ?? dayjs(objVal).unix();
    
    public customMerge: 
    (
        objValue: unknown, 
        srcValue: unknown, 
        key?: string, 
        object?: Record<string, unknown>, 
        source?: Record<string, unknown>, 
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        stack?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) => any 
    = this.standardMerge;


    reducer(this: BaseDuck<T>, state: Record<string | number | symbol, T> = this.DEFAULT_STATE, action: AnyAction = {type: 'none'}): Record<string | number | symbol, T> {

        if (action.type === this.basicTypes.RESET) return this.DEFAULT_STATE
        if (action.type === this.basicTypes.FUNCTION)
            if (action.callback) return action.callback(state)
            else return state
            
        return produce(state, draft => {
            const {type, payload} = action;

            if (type === types.MESSAGE) {
                const message = payload;
                if (!message || message.error) return;

                const { records } = message;
                if (!records || !Array.isArray(records)) return;
                const customizer = mergeById(this.replaceKeys, this.setKeys);

                for (const record of records) {
                    if (record[ this.duckName ]) {
                        const changeObjects = record[ this.duckName ];
                        if (!changeObjects || !Array.isArray(changeObjects)) break;
                        for (const changeObject of changeObjects) {
                            if (Object.prototype.toString.call(changeObject) !== '[object Object]') break;
                            const {_id} = changeObject;
                            if (draft[_id]) {
                                if (changeObject["~delete~"]) {
                                    delete draft[_id]
                                } else {
                                    mergeWith(draft[_id], changeObject, customizer)
                                }
                            } else {
                                draft[_id] = changeObject
                            }
                        }
                    }
                }
                return;
            }
                    

            if (!payload?.id) return;
            
            switch(type) {
                case this.basicTypes.INSERT:
                    draft[payload.id] = payload.data
                    break
                case this.basicTypes.UPSERT:
                    if (draft[payload.id] !== undefined) {
                        mergeWith(draft[payload.id], payload.data, mergeById(this.replaceKeys))
                    } else {
                        draft[payload.id] = payload.data
                    }
                    break
                case this.basicTypes.REMOVE: {
                    console.log( 'Delete ' + payload.id )
                    delete draft[ payload.id ]
                    break
                }
            }
            this.customReducer(draft, action)
        })
    }

    insert(insertObject: T, id: string | number) {
        return {
            type: this.basicTypes.INSERT,
            payload: {
                id,
                data: insertObject
            }
        }
    }

    upsert(updateObject: DeepPartial<T>, id: string | number) {
        return {
            type: this.basicTypes.UPSERT,
            payload: {
                id,
                data: updateObject
            }
        }
    }

    remove(id: string | number) {
        return {
            type: this.basicTypes.REMOVE,
            payload: {
                id
            }
        }
    }

    reset() {
        return {
            type: this.basicTypes.RESET,
            payload: null
        }
    }

    callback( cb: (state: Record<string | number | symbol, T>)=>Record<string | number | symbol, T> ) {
        return {
            type: this.basicTypes.FUNCTION,
            callback: cb
        }
    }

    public customTypes: Record<string, string>={}

    get types() {
        return {
            ...this.basicTypes,
            ...this.customTypes
        }
    }
    public actions: Record<string, (...args: any[])=>AnyAction>  = {}

    public replaceKeys: string[] = [];
    public setKeys: string[] = [];
    // eslint-disable-next-line
    public customReducer( draft: WritableDraft<Record<string | number | symbol, T>>, action: AnyAction ) {  }

}