////// Packages //////////////////
import { useEffect, useState, FC } from "react";

////// Local Components //////////

////// Global Components /////////
import Avatar from "./Avatar";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

////// CommonJS Helpers //////////
import { isIterableArray } from '../helpers/utils';

////// Custom Hooks //////////////
import usePeopleList from 'store/hooks/person/usePeopleList'
import makeName from "helpers/makeName";

////// Thunks ////////////////////


interface Props {
    ids: string[];
    size?: 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl'; 
    rounded?: 'circle' | '0' | 'soft' ;
    emoji?: string;
    className?: string;
    mediaClass?: string;
    isExact?: boolean;
    icon?: IconProp;
}

const PersonAvatar: FC<Props> = ({ ids, size="xl", rounded, emoji, className, mediaClass, isExact, icon }) => {
    const [name, setName] = useState('');
    const [src, setSrc] = useState([] as string[]);
    const people = usePeopleList(...ids).value

    useEffect( () => {
        if (people && isIterableArray(people)) {
            if ( people.length > 1) {
                setSrc( people.map( person => person?.avatar?.location || '') );
                setName( makeName( people[0].name, { first: true, common: true, last: true } ) );
            } else {
                setSrc([people[0]?.avatar?.location || '']);
                setName( makeName( people[0]?.name, { first: true, common: true, last: true } ) );
            }
        } else {
            setSrc([]);
            setName('');
        }
    }, [JSON.stringify(people)])


    return <Avatar
            src={src} 
            name={name} 
            size={size} 
            rounded={rounded} 
            emoji={emoji} 
            className={className} 
            mediaClass={mediaClass} 
            isExact={isExact} 
            icon={icon} />
}

export default PersonAvatar;