////// Packages //////////////////
import React, {FC} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'reactstrap';

////// Local Components //////////

////// Global Components /////////
import Flex from 'components/Flex';
import { RouteDef } from 'routes/routes';

////// CommonJS Helpers //////////

////// Custom Hooks //////////////

////// Thunks ////////////////////


interface Props {
  route: RouteDef;
}

const NavbarVerticalMenuItem: FC<Props> = ({ route }) => (
  <Flex align="center">
    {route.icon && (
      <span className="nav-link-icon">
        <FontAwesomeIcon icon={route.icon} />
      </span>
    )}
    <span className="nav-link-text">{route.name}</span>
    {!!route.badge && (
      <Badge color={route.badge.color || 'soft-success'} pill className="ml-2">
        {route.badge.text}
      </Badge>
    )}
  </Flex>
);

export default React.memo(NavbarVerticalMenuItem);
