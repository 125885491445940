////// Packages //////////////////
import dayjs, { Dayjs } from 'dayjs'
import React, { FC } from 'react'

////// Local Components //////////

////// Global Components /////////

////// CommonJS Helpers //////////

////// Custom Hooks //////////////

////// Thunks ////////////////////

////// Types /////////////////////

interface Props {
    
}

const TestPage: FC<Props> = () => {
    
    const startDate = dayjs().subtract(15, 'day').format('MM/DD/YYYY')
    const endDate = '01/15/2022'

    let date = dayjs(startDate);

    type Year = {
        value: number,
        firstDate: Dayjs,
        lastDate: Dayjs,
        months: Month[]
    }
    type Month = {
        title: string;
        firstDate: Dayjs,
        lastDate: Dayjs,
        days: Day[]
    }
    type Day = {
        date: Dayjs,
        number: number,
        weekday: number
    }

    const years: Year[] = [];

    while (date.isBefore(endDate)) {

        date = date.add(1, 'day')
    }

    
    return (
        <div className="border">
            <div className="gantt-month-container">
                <div className="gantt-month-title">

                </div>
            </div>
        </div>
    )
}

export default TestPage