import { FC } from 'react';
import NavbarDropdown, {NavbarDropdownProps} from './NavbarDropdown';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

interface Props extends NavbarDropdownProps {
    isDropdown?: boolean;
    linkTo?: string;
    title: string;
}

const NavbarItem: FC<Props> = ({ isDropdown, linkTo, title, children, ...rest }) => (
    <>
        { isDropdown ? 
                <NavbarDropdown { ...rest } title={title} >
                    {children}
                </NavbarDropdown>
            :   
                <NavLink to={linkTo} tag={Link} >
                    { title }
                    { children }
                </NavLink>
        }
    </>
)

export default NavbarItem;
