import { 
    MStack,
    MBoard,
    MThread,
    MTask,
    $StackData
} from "store/types/models/stack";
import { BaseDuck } from "./BaseDuck";
import { combineReducers } from 'redux';


export const stacks = new BaseDuck<MStack>('stacks');
stacks.setKeys = ['boards']

export const boards = new BaseDuck<MBoard>('boards');
boards.setKeys = ['tasks', 'threads', 'tags'];
boards.replaceKeys = ['coordinates', 'permissions'];

export const tasks = new BaseDuck<MTask>('tasks');
tasks.setKeys = [];

export const threads = new BaseDuck<MThread>('threads');
threads.setKeys = ['members'];

export const stackTable = new BaseDuck<$StackData[0]>('stackTable');
stackTable.replaceKeys = ['tags'];

export const stackReducer = combineReducers({
    stacks: stacks.reducer.bind(stacks),
    boards: boards.reducer.bind(boards),
    tasks: tasks.reducer.bind(tasks),
    threads: threads.reducer.bind(threads),
    stackTable: stackTable.reducer.bind(stackTable)
})