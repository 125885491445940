import {$fileupload} from 'environ'

interface FileUploadResponse {
    success: true;
    files: {
        document?: string;
        avatar?: string;
        photo?: string;
        cover?: string;
    }
}
 const uploadDoc = async (file: Blob, doctype: 'document' | 'avatar' | 'photo' | 'cover', name?: string) => {

    const formData = new FormData();

    formData.append('fupload', file, name);

    const res = await fetch($fileupload + '/' + doctype, {
                            method: 'POST', 
                            body: formData,
                            credentials: 'include'
                        })

    const data = await res.json() as FileUploadResponse | {success: false; files: undefined} | null;

    console.log(data);

    return data;
}

export default uploadDoc;