///// Packages /////////////
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import loadable from '@loadable/component';

///// Local Components /////////
import LoadingPage from './layouts/test/Loading';

///// Routes //////
import ErrorRoutes from './routes/errors/ErrorRoutes';
import AuthenticatedAppRoute from './routes/AuthenticatedAppRoute';
import TestPage from 'layouts/test/TestPage';

const Landing = loadable(() => import('./layouts/landing/Landing'));
const AuthRoutes = loadable(() => import('./routes/auth/AuthRoutes'));


const AuthGateway = () => {
    const isLoggedIn = useSelector( state => state.app.login.loggedIn )
    return (
        <Router>
            <Switch>
                <Route path='/loading' exact component={LoadingPage} />
                <Route path='/test' exact component={TestPage} />
                <Route path='/' exact component={Landing} />
                <Route path='/auth' component={AuthRoutes} />
                <Route path='/errors' component={ErrorRoutes} />
                { isLoggedIn ? 
                    <Route component={ AuthenticatedAppRoute } />
                :
                    <Redirect to="/auth/login" />
                } 
            </Switch>
        </Router>
    );
}

export default AuthGateway;