import { combineReducers } from "redux";
import { 
    MPerson,
    MUser,
    MPersonPhoto, 
    MUserGroup,
    MOrganization,
    MOrganizationPhoto
} from "../types/models/id";
import { BaseDuck } from "./BaseDuck";

export const people = new BaseDuck<MPerson>('people');
people.customMerge = people.replaceArrays;

export const personPhotos = new BaseDuck<MPersonPhoto>('personPhotos');

export const users = new BaseDuck<MUser>('users');
users.replaceKeys = ['explicitPermissions', 'mailingAddress', 'people', 'boards', 'threads']

export const usergroups = new BaseDuck<MUserGroup>('usergroups');
usergroups.customMerge = usergroups.replaceArrays;

export const organizations = new BaseDuck<MOrganization>('organizations');
organizations.replaceKeys = ['addresses']
organizations.setKeys = ['websites', 'tags']

export const organizationPhotos = new BaseDuck<MOrganizationPhoto>('organizationPhotos');

export const idReducer = combineReducers({
    people: people.reducer.bind(people),
    personPhotos: personPhotos.reducer.bind(personPhotos),
    users: users.reducer.bind(users),
    usergroups: usergroups.reducer.bind(usergroups),
    organizations: organizations.reducer.bind(organizations),
    organizationPhotos: organizationPhotos.reducer.bind(organizationPhotos)
})