import { combineReducers } from "redux";
import { MAnnouncement, MToDo, PopulatedAssignment } from "store/types/models/management";
import { BaseDuck } from "./BaseDuck";


export const toDos = new BaseDuck<MToDo>('todos');

export const assignments = new BaseDuck<PopulatedAssignment>('assignments');

export const announcements = new BaseDuck<MAnnouncement>('announcements');
announcements.setKeys = ['readReceipts'];

export const managementReducer = combineReducers({
    toDos: toDos.reducer.bind(toDos),
    announcements: announcements.reducer.bind(announcements),
    assignments: assignments.reducer.bind(assignments)
})