////// Packages //////////////////
import React, { useCallback, useState, FC } from "react";

////// Local Components //////////

////// Global Components /////////

////// CommonJS Helpers //////////
import getSizedImageName from "../helpers/getSizedImageName";
////// Custom Hooks //////////////

////// Thunks ////////////////////

export interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  size?: "s" | "m" | "l" | "original";
  noSize?: boolean;
  placeholderClass?: string;
}

const Image: FC<Props> = ({
  size = "original",
  noSize,
  src = "",
  alt = "",
  onLoad,
  className = "",
  width = "auto",
  height = "auto",
  placeholderClass,
  ...imageProps
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const handleLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement, Event>) => {setIsImageLoaded(true); onLoad && onLoad(e)}, []);

  return (
    <>
      <img
        className={[className, isImageLoaded ? "d-block" : "d-none"].join(" ")}
        src={noSize ? src : getSizedImageName(src, size)}
        alt={alt}
        onLoad={handleLoad}
        width={width}
        height={height}
        {...imageProps}
      />
      {!isImageLoaded && (
        <div style={{ width, height }} className={["image-placeholder", placeholderClass].join(' ')} />
      )}
    </>
  );
};

export default Image;
