////// Packages //////////////////
import { Collapse, Navbar, NavItem, Nav } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isEqual } from 'lodash';

////// Local Components //////////
import SearchBox from '../../compositions/SearchBox';
import TopNavRightSideNavItem from './components/TopNavRightSideNavItem';
import NavbarTopDropDownMenus from './components/NavbarTopDropDownMenus';

////// Global Components /////////
import Logo from 'compositions/Logo';

////// CommonJS Helpers //////////
import { navbarBreakPoint, topNavbarBreakpoint } from 'environ';
import { settings } from "store/ducks/settings";

////// Custom Hooks //////////////

////// Thunks ////////////////////


const NavbarTop = () => {
  const dispatch = useDispatch();
  const {
    showBurgerMenu,
    isTopNav,
    isVertical,
    isCombo,
    navbarCollapsed
  } = useSelector( store => store.app.settings, isEqual );

  const handleBurgerMenu = () => {
    if (isTopNav && !isCombo) dispatch(settings.actions.setNavbarCollapsed(!navbarCollapsed));
    if (isCombo || isVertical) dispatch(settings.actions.setShowBurgerMenu(!showBurgerMenu));
  };

/*   const setNavCollapsed = useCallback(value => dispatch(settings.actions.setNavbarCollapsed(value)), [dispatch]) */

  return (
    <Navbar
      light
      className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit"
      expand={topNavbarBreakpoint}
    >
      <div
        className={classNames('toggle-icon-wrapper mr-md-3 mr-2', {
          'd-lg-none': isTopNav && !isCombo,
          [`d-${navbarBreakPoint}-none`]: isVertical || isCombo
        })}
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={ handleBurgerMenu }
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </div>
      <Logo at="navbar-top" width={120} />
      <Collapse navbar isOpen={navbarCollapsed} className="scrollbar">
        {!isTopNav ? (
          <Nav navbar className="align-items-center d-none d-lg-block">
            <NavItem>
              <SearchBox />
            </NavItem>
          </Nav>
        ) : (
          <Nav navbar>
            <NavbarTopDropDownMenus />
          </Nav>
        )}
      </Collapse>
      <TopNavRightSideNavItem />
    </Navbar>
  );
};

export default NavbarTop;
