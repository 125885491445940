const PERMISSIONS = {
    GLOBAL_ADMIN: 'Organization global administrator',
    stack: {
        EDIT_STACK_INFO: "Edit this stack's information",
        EDIT_STACK_TASKS: "Edit this stack's milestones and tasks",
        CREATE_ASSIGNMENTS: 'Create assignments in this stack',
        VIEW_BUDGET_INFO: 'View this budget information of this stack',
        VIEW_TIME_CHARGED: 'View time charged to this stack',
        VIEW_DOCUMENTS: 'View documents in this stack',
        EDIT_DOCUMENTS: 'Add or edit documents in this stack',
        EDIT_PHOTOS: 'Add or edit photos in this stack',
        INVITE_USERS: 'Invite users to join',
        SET_USER_PERMISSIONS: 'Set permissions for other users in this stack'
    },
    EDIT_ORGANIZATION: 'Edit the organization',
    EDIT_SYSTEM_SETTINGS: 'Edit system settings',
    CREATE_NEW_USERS: 'Add and edit organization users',
    ASSIGN_MANAGERS: 'Assign employees to managers',
    CREATE_PERMISSIONS_GROUP: 'Create a permissions group',
    ASSIGN_EMPLOYEES_TO_PERMISSIONS_GROUP: 'Add employees to a permission group',
    ANNOUNCEMENTS: 'Create and edit announcements',

    EDIT_ALL_TIMESHEETS: 'Edit any timesheet',
    EDIT_CLOSED_TIMESHEETS: 'Edit closed timesheets',
    UNIVERSAL_APPROVAL_TIMESHEET: 'Approve any timesheet',
    UNIVERSAL_APPROVAL_TIMEOFF: 'Approve any time off',

    CREATE_STACKS: 'Create new stacks',
    EDIT_ANY_STACK_INFO: "Edit any stack's information",
    VIEW_ANY_BOARD_INFO: "View any stack's board info",
    EDIT_ANY_BOARD_INFO: "Edit any stack's board info",
    EDIT_ANY_BOARD_TASKS: "Edit any stack's milestones and tasks",
    CREATE_ASSIGNMENTS: 'Create assignments',
    VIEW_TIME_CHARGED: 'View all time charged',
    POST_COMMENTS: 'Post notes on any board',
    GENERATE_REPORTS: 'Generate administrative reports',
    VIEW_BUDGET_INFO: 'View any contract or stack budget information',

    ENTER_ANY_EXPENSE: "Create expenses on others' behalf",
    UNIVERSAL_APPROVAL_EXPENSE: 'Create or approve any expense',
    ACCOUNTING_ADMIN: 'Create, modify, and view ledgers',

    CREATE_TIME_CODES_AND_TIME_TYPES: 'Create or edit time codes and time types',
    CREATE_CONTRACT: 'Create and edit contracts',
    SIGN_CONTRACT: 'Sign contracts',
    VIEW_INVOICES: 'View generated invoices',
    CREATE_INVOICES: 'Create and edit generated invoices',
    APPROVE_INVOICES: 'Approve generated invoices',
    SET_WAGES: 'Set Employee Wages',
    CREATE_PAYSTUBS: 'Create, view, delete, and update paystubs for an employee',
    EDIT_CONTACTS: "Create and edit contacts' information"
} as const

export default PERMISSIONS;