import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FalconColors } from 'components/falconTypes';
import haBoardTripod from 'assets/icons/haBoardTripod';

export interface RouteDef {
  name: string;
  to: string;
  icon?: IconProp;
  exact?: boolean;
  children?: RouteDef[];
  badge?: {
    color: FalconColors;
    text: string;
  }
}

//still needs config
export const homeRoutes: RouteDef = {
  name: 'Home',
  to: '/home',
  exact: true,
  icon: 'chart-pie'
};

export const adminRoutes: RouteDef = {
  name: 'Administration',
  to: '/admin',
  icon: 'toolbox',
  children: [
    { to: '/admin/organization', name: 'My Organization' },
    { to: '/admin/global-calendar', name: 'Global Calendar' },
    { to: '/admin/users', name: 'Users' },
    { to: '/admin/user-groups', name: 'Groups' },
    { to: '/admin/account', name: 'Account' },
    { to: '/admin/billing', name: 'Billing' },
    { to: '/admin/settings', name: 'System Settings'}
  ]
};

export const stackRoutes: RouteDef = {
  name: 'Boards',
  to: '/stacks',
  icon: haBoardTripod,
  children: [
    { to: '/stacks/details', name: 'Details' },
    { to: '/stacks/map', name: 'Map' }
  ]
};

export const timeRoutes: RouteDef = {
  name: 'Time',
  to: '/time',
  icon: 'clock',
  children: [
    { to: '/time/calendar', name: 'Calendar' },
    { to: '/time/timesheet', name: 'Timesheet' },
    { to: '/time/timeoff', name: 'Time Off' }
  ]
};

export const socialRoutes: RouteDef = {
  name: 'Social',
  to: '/social',
  icon: 'users',
  children: [
    { to: `/social/profile/my`, name: 'My Profile' },
    { to: '/social/people', name: 'People' },
    { to: '/social/organizations', name: 'Organizations' },
    { to: '/social/relationships', name: 'Relationships' },
    { to: '/social/chat', name: 'Chat' }
  ]
};

export const financeRoutes: RouteDef = {
  name: 'Finance',
  to: '/finance',
  icon: 'dollar-sign',
  children: [
    { to: '/finance/wages', name: 'My Pay' },
    { to: '/finance/expenses', name: 'Expenses' },
    { to: '/finance/contracts', name: 'Contracts' },
    { to: '/finance/invoices', name: 'Invoices' },
    { to: '/finance/payable', name: 'Payable' },
    { to: '/finance/receivable', name: 'Receivable' },
    { to: '/finance/payroll', name: 'Payroll' },
    { to: '/finance/accounts', name: 'Accounts'}
  ]
};

export const managementRoutes: RouteDef = {
  name: 'Management',
  to: '/management',
  icon: 'chart-line',
  children: [
    { to: '/management/assignments', name: 'Assignments' },
    { to: '/management/my-calendar',  name: 'My Calendar' },
    { to: '/management/teams', name: 'Teams' },
    { to: '/management/workload', name: 'Workload'},
    { to: '/management/approvals', name: 'Approvals' },
    { to: '/management/timelines', name: 'Timelines' }
  ]
}

export const reportingRoutes: RouteDef = {
  name: 'Reporting',
  to: '/reports',
  icon: 'stream',
  exact: true
}

const routes = [
  homeRoutes,
  managementRoutes,
  stackRoutes,
  timeRoutes,
  financeRoutes,
  socialRoutes,
  reportingRoutes,
  adminRoutes
];

export default routes;
