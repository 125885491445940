////// Packages //////////////////
import React, { useEffect, useState, FC } from "react";

////// Local Components //////////

////// Global Components /////////

////// CommonJS Helpers //////////
import getSizedImageName from "helpers/getSizedImageName";
import HappinImage from "./Image";
////// Custom Hooks //////////////

////// Thunks ////////////////////

const useProgressiveImage = (
  lowQualitySrc: string,
  highQualitySrc: string
): [string, boolean] => {
  const [src, setSrc] = useState(lowQualitySrc);

  useEffect(() => {
    setSrc(lowQualitySrc);

    const img = new Image();
    img.src = highQualitySrc;

    img.onload = () => {
      setSrc(highQualitySrc);
    };

    return () => {
      img.onload = null;
    };
  }, [lowQualitySrc, highQualitySrc]);

  return [src, src === lowQualitySrc];
};

interface Props {
  className?: string;
  src: string | undefined | null;
  style?: React.CSSProperties;
  alt?: string;
  size?: "s" | "m" | "l" | "original";
  blurRadius?: number;
  width?: number | string;
  height?: number | string;
}

const ProgressiveImage: FC<Props> = ({
  className,
  src,
  style,
  alt = "",
  size = "original",
  blurRadius = 6,
  width = "auto",
  height = "auto",
  ...props
}) => {
  const [source, blur] = useProgressiveImage(
    getSizedImageName(src, "s"),
    getSizedImageName(src, size) + " "
  );

  return (
    <HappinImage
      style={{
        ...style,
        filter: blur
          ? `blur(${
              typeof blurRadius === "number" ? blurRadius + "px" : blurRadius
            })`
          : "none",
        transition: blur ? "none" : "filter 0.3s ease-out"
      }}
      className={className}
      src={source}
      alt={alt}
      width={width}
      height={height}
      noSize
      {...props}
    />
  );
};

export default ProgressiveImage;
