import deduplicator from 'store/ducks/deduplicator';
import { ThunkAction } from "redux-thunk";
import { DefaultRootState } from "react-redux";

function deduplicate (testValue: (string|number)[], cb: ()=>void, timeout: number, force?: boolean):
    ThunkAction<void | null, DefaultRootState, any, any> {
        return (dispatch, getState) => {
            try {
                if (force) throw 'forced'
                const state = getState();
                let obj: Record<string | number, any> = state.deduplicator
                for ( let i = 0; i < testValue.length; i++ ) {
                    //console.log('deduplicate', obj, Date.now())
                    obj = obj[testValue[i]]
                    if (!obj) throw 'not found'
                    
                }
                const expiration = obj as unknown as number
                if ( expiration && expiration < Date.now() ) {
                    console.log('expired')
                    throw 'expired'
                }
                return null;
            } catch(err) {
                dispatch( deduplicator.actions.set( testValue, timeout + Date.now() ) )
                return cb()
            }
        }
}

export default deduplicate;