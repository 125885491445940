////// Packages //////////////////
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

////// Local Components //////////

////// Global Components /////////
import Error404 from 'layouts/errors/Error404';
import Error500 from 'layouts/errors/Error500';
import Section from 'components/Section';
import Logo from 'compositions/Logo';

////// CommonJS Helpers //////////

////// Custom Hooks //////////////

////// Thunks ////////////////////



const ErrorRoutes = ({ match: { url } }) => (
  <Section className="py-0">
    <Row className="flex-center min-vh-100 py-6">
      <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
        <Logo />
        <Switch>
          <Route path={`${url}/404`} component={Error404} />
          <Route path={`${url}/500`} component={Error500} />

          {/*Redirect*/}
          <Redirect to={`${url}/404`} />
        </Switch>
      </Col>
    </Row>
  </Section>
);

ErrorRoutes.propTypes = { match: PropTypes.object };

export default ErrorRoutes;
