import React, { FC } from 'react';
import { cssTransition } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Fade = cssTransition({ enter: 'fadeIn', exit: 'fadeOut' });

interface Props {
  closeToast?: ()=>void;
}
export const CloseButton: FC<Props> = ({ closeToast }) => (
  <FontAwesomeIcon icon="times" className="my-2 fs--2" style={{ opacity: 0.5 }} onClick={closeToast} />
);
