import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { ActionCreator, WsMessage } from 'store/types/interface/common';
import useWS from './useWS';

export const useAction = (actionCreator?: ActionCreator<any>, timeout = 3000) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { send } = useWS();
    const [requestId, setRequestId] = useState(null as null | string);
    const response = useSelector( state => state.ws.messageLog.find( message => message.requestId === requestId ) as WsMessage )

    const resolver = useRef( null as null |( (value: WsMessage)=>void) );

    useEffect(() => {
        if (response) {
            if (resolver.current) resolver.current(response)
        }
    }, [response])

    const post = useCallback( async (...params: any[]): Promise<WsMessage | null> => {
        if ( !actionCreator ) return null;

        setLoading(true);
        const timer = setTimeout(() => {
            setLoading(false);
            setError('Request Timed Out');
        }, timeout)
        const actions = await actionCreator(...params)
        if (actions.cancel) {
            clearTimeout(timer);
            setLoading(false);
            return null;
        }
        const msgId = send(actions)
        setRequestId(msgId);

        const data: WsMessage = await new Promise( (resolve, reject) => {
            resolver.current = resolve;
        })
        clearTimeout(timer)
        setLoading(false)
        if (data?.success) {
            setError('');
        } else {
            console.log(data?.error);
            setError(data?.error || "Something went wrong")
        }
        
        return data;

    }, [actionCreator, timeout])

    const actionPackage = useMemo(() => ({
        loading,
        error,
        post
    }), [loading, error, post])

    return actionPackage
}

export default useAction;