export const $api = "wss://api.happin.design/api";                        
export const $fileserv = "https://file.happin.design/files";              
export const $fileupload = "https://file.happin.design/upload";           
export const $authentication = "https://api.happin.design/authentication";
export const version = '0.0.1';

export const navbarBreakPoint = 'xxl'; // Vertical navbar breakpoint

export const topNavbarBreakpoint = 'lg';

export const autoDropBreakpoint = 992;

export const WEBSOCKET_PREFIX = 'happin/websocket/';

export const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

export const currencyFormatInt = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0})

export const expensePaymentMethods = ['Out of Pocket', 'Company Card', 'Company Accounts', 'Cash', 'No Direct Payment', 'Other'];