import React, { FC } from "react";
import classNames from "classnames";

interface Props extends Omit<React.HTMLProps<HTMLDivElement>, 'wrap'> {
  justify?: "start" | "center" | "end" | "between" | "around";
  align?: "start" | "center" | "end";
  inline?: boolean;
  column?: boolean;
  wrap?: boolean;
  className?: string;
  tag?: any;
  children?: React.ReactNode;
  id?: string;
  onClick?: (...args: any[]) => void;
  innerRef?(elementRef: HTMLElement): any;
}

const Flex: FC<Props> = ({
  justify,
  align,
  inline,
  column,
  wrap,
  className,
  tag: Tag = "div",
  children,
  id,
  innerRef,
  ...rest
}) => {
  return (
    <Tag
      className={classNames(
        {
          "d-flex": !inline,
          "d-inline-flex": inline,
          [`justify-content-${justify}`]: justify,
          [`align-items-${align}`]: align,
          "flex-column": column,
          "flex-wrap": wrap
        },
        className
      )}
      ref={innerRef}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default Flex;
