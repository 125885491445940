import { combineReducers } from 'redux';
import {
    MCalendarEvent,
    MGlobalCalendarEvent,
    MTimeBankWithCharged,
    MTimeEntry,
    MTimeOffRequest,
    MTimeType,
//    TimeOffBank,
//    TimeOffPlan,
//    TimesheetEntry
} from 'store/types/models/time';

import { BaseDuck } from './BaseDuck';

export const timeEntries = new BaseDuck<MTimeEntry>('timeEntries');

export const timeTypes = new BaseDuck<MTimeType>('timeTypes');

export const timeOffBanks = new BaseDuck<MTimeBankWithCharged>('timeOffBanks');
timeOffBanks.setKeys = ['accrueWeekdays', 'accrueWeeks', 'accrueDates', 'accrueMonths']

export const timeOffRequests = new BaseDuck<MTimeOffRequest>('timeOffRequests');

export const calendarEvents = new BaseDuck<MCalendarEvent | MGlobalCalendarEvent>('calendarEvents')

// export const timesheet = new BaseDuck<TimesheetEntry>('timesheet');

export const reducer = combineReducers({
    timeEntries: timeEntries.reducer.bind(timeEntries),
    timeTypes: timeTypes.reducer.bind(timeTypes),
    timeOffBanks: timeOffBanks.reducer.bind(timeOffBanks),
    timeOffRequests: timeOffRequests.reducer.bind(timeOffRequests),
    calendarEvents: calendarEvents.reducer.bind(calendarEvents)
 //   timesheet: timesheet.reducer,
})