////// Packages //////////////////
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';

////// Local Components //////////

////// Global Components /////////
import PersonAvatar from 'components/PersonAvatar';

////// CommonJS Helpers //////////
import autoDrop from 'helpers/autoDrop';

////// Custom Hooks //////////////

////// Thunks ////////////////////


const ProfileDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const personId = useSelector( state => state.app.login.personId)
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const autoDropdown = autoDrop(setDropdownOpen);
  
  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={autoDropdown(true, true)}
      onMouseLeave={autoDropdown(false, false)}
    >
      <DropdownToggle nav className="pr-0">
        <PersonAvatar ids={[personId!]} />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem className="font-weight-bold text-warning" href="#!">
            <FontAwesomeIcon icon="crown" className="mr-1" />
            <span>Go Pro</span>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem href="#!">Set status</DropdownItem>
          <DropdownItem tag={Link} to="/social/profile/my">
            Profile
          </DropdownItem>
          <DropdownItem href="#!">Feedback</DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to="/social/editprofile">
            Settings
          </DropdownItem>
          <DropdownItem tag={Link} to="/auth/logout">
            Logout
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
