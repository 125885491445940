import produce from "immer";
import { PRODUCTIVITY_RANGE } from "store/types/enums";
import { ProductivityStats } from "store/types/interface/management";

export enum TYPES {
    MERGE='happin/productivity/merge',
    RESET='happin/productivity/RESET'
}

interface ProductivityStat {
    billable: Record<PRODUCTIVITY_RANGE, number>;
    unbillable: Record<PRODUCTIVITY_RANGE, number>;
}
export interface ProductivityState {
    [id: string]: ProductivityStat
}

type ValidActions = {
    type: TYPES.MERGE,
    payload: Record<PRODUCTIVITY_RANGE, ProductivityStats[]>
} | {
    type: TYPES.RESET,
    payload: any
} | {
    type: any; 
    payload: any;
}

export function reducer( state: ProductivityState = {}, action: ValidActions = {type: null, payload: null} ) {
    
    switch(action.type) {
        case TYPES.MERGE: {
            const draft = {...state}
                const n: ProductivityState = {}
                const ranges = [PRODUCTIVITY_RANGE.LAST_2_YEARS, PRODUCTIVITY_RANGE.LAST_YEAR, PRODUCTIVITY_RANGE.YTD, PRODUCTIVITY_RANGE.SIX_MO]
                for (const r of ranges) {
                    const s = action.payload[r]
                    if (!s) continue;
                    for (const record of s) {
                        n[record._id.personId] = n[record._id.personId] || {}
                        const billType = record._id.billable ? 'billable' : 'unbillable'
                        n[record._id.personId][billType] = n[record._id.personId][billType] || {}
                        n[record._id.personId][billType][r] = record.hours
                    }
                }
                Object.assign( draft, n )
                return draft
        }
        case TYPES.RESET:
            return {}
        default:
            return state
    }
}

// let key: PRODUCTIVITY_RANGE
//                     for (key in p) {
//                         const targetPersonId = p[ key ]._id.personId
//                         n[ targetPersonId ] = n[ targetPersonId ] || {}
//                         if (p[key]._id.billable) {
//                             n[ targetPersonId ].billable = {}
//                         }
//                         if ( draft[ targetPersonId ] ) {

//                             if ( draft[ targetPersonId ][ key ] ) {

//                             }
//                         }
//                     }