import React, { FC } from 'react';
import Lottie from 'react-lottie';
import animationData from 'assets/img/logos/pinAnimationDualDirection.json'

export interface Props {
    width?: number;
    horiz?: boolean;
    className?: string;
    pinStrokeRGB?: {r: number; g: number; b: number;};
    style?: React.CSSProperties
}

const SpinnerLogo: FC<Props> = ({style, width, horiz, className, pinStrokeRGB = {r: 44, g: 123, b: 229}}) => {
    const _r = pinStrokeRGB.r / 255;
    const _g = pinStrokeRGB.g / 255;
    const _b = pinStrokeRGB.b / 255;

    const arrColors = animationData.layers[0].shapes[0].it![1].c!.k;
    arrColors[0] = _r;
    arrColors[1] = _g;
    arrColors[2] = _b;

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className={className} style={style}>
            <Lottie options={defaultOptions} style={{ width, marginRight: 0, marginLeft: 0 }} />
        </div>
    )

};

export default SpinnerLogo;