import { useEffect } from 'react';
import { useSelector } from 'react-redux'
import loadable from '@loadable/component';
import SimpleReactLightbox from 'simple-react-lightbox';

import NavbarVertical from '../layouts/navbar/NavbarVertical';
import NavbarTop from '../layouts/navbar/NavbarTop';
import { Route, Switch } from 'react-router';
import useMyUser from 'store/hooks/user/useMyUser';

const HomeRoutes = loadable(() => import('./home/HomeRoutes'));
const FinanceRoutes = loadable(() => import('./finance/FinanceRoutes'));
const TimeRoutes = loadable(() => import('./time/TimeRoutes'));
const ManagementRoutes = loadable(() => import('./management/ManagementRoutes'));
const SocialRoutes = loadable(() => import('./social/SocialRoutes'));
const AdminRoutes = loadable(() => import('./admin/AdminRoutes'));
const StackRoutes = loadable(() => import('./stacks/StackRoutes'));
const checkFluidPages = (a) => true;

const AuthenticatedAppRoute = () => {
    const { value: _user } = useMyUser()
    const isFluid = useSelector( state => state.app.settings?.isFluid )
    const isVertical = useSelector( state => state.app.settings?.isVertical )
    
    const forceFluid = checkFluidPages( window.location.pathname )

    useEffect(() => {
        SocialRoutes.preload()
    }, []);

    // reset window position on navigation
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [window.location.pathname]);

    return (
        <SimpleReactLightbox>
            <div className={isFluid || forceFluid ? 'container-fluid' : 'container'}>
                { isVertical &&
                    <NavbarVertical />
                }
                <div className="content">
                    <NavbarTop />
                    <Switch>
                        <Route path="/finance" component={FinanceRoutes} />
                        <Route path='/social' component={SocialRoutes} />
                        <Route path="/management" component={ManagementRoutes} />
                        <Route path='/time' component={TimeRoutes}/>
                        <Route path='/admin' component={AdminRoutes}/>
                        <Route path='/stacks' component={StackRoutes} />
                        <Route path="/home" component={HomeRoutes} />
                    </Switch>
                    <div id="footer-spacer" style={{minHeight: "20vh"}} />
                </div>
            </div>
        </SimpleReactLightbox>
    );
};


export default AuthenticatedAppRoute;
