import { MBoard } from "store/types/models/stack";

export enum ACTIONS {
    SET="happin/forms/myBoards/SET"
}
export function reducer(state: MBoard['_id'][] = [], action: {type: ACTIONS.SET, payload: MBoard['_id'][]}) {
    if (action?.type === ACTIONS.SET) {
        return action.payload
    } else {
        return state
    }
}