import { $fileserv } from "../environ";

function getSizedImageName(src: string, size: "s" | "m" | "l" | "original") {
  if (!src) return "";
  const validSizes = ["s", "m", "l", "original"];
  const sizeExtension = validSizes.includes(size) ? "_" + size : "";

  let newSrc = "";
  const arrSrc = src.split(".");
  if (Array.isArray(arrSrc) && arrSrc.length > 1) {
    arrSrc[arrSrc.length - 2] += sizeExtension;
    newSrc = $fileserv + "/" + arrSrc.join(".");
  }

  return newSrc;
}

export default getSizedImageName;
