import { isEqual } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { getSetPeopleById } from 'store/thunks/people';
import useLoadAndSelect from 'store/hooks/useLoadAndSelect';
import { $BasicPerson } from 'store/types/models/id';


function usePeopleList(...ids: string[]) {
    const peopleToRetrieve = useSelector( state => ids.filter( id => !state.id.people[id]?.name ), shallowEqual )

    const peopleBasicInfo = useLoadAndSelect<$BasicPerson[]>({
        actionCreator: peopleToRetrieve?.length > 0 ? getSetPeopleById : undefined,
        selector(state) {
            return ids.map( id => {
                const person = state.id.people[id];
                return {
                    _id: id,
                    avatar: person?.avatar, 
                    name: person?.name,
                    position: person?.position,
                    organizationId: person?.organizationId
                }
            })
        },
        equality: isEqual,
        postParams: peopleToRetrieve,
        deduplicate: ['basicPeople', peopleToRetrieve.join('~')]
    })

    return peopleBasicInfo;
}

export default usePeopleList;