import uploadDoc from "helpers/uploadDoc";
import { people as peopleDuck } from '../ducks/id'
import {
    ImageData
} from 'store/store';
import { IEducation, IEmail, IExperience, IName, IPhone, IPhoto, MOrganization, MPerson } from "store/types/models/id";
import { BaseDuck } from "store/ducks/BaseDuck";
import { ActionCreator } from "store/types/interface/common";

interface PeopleController extends BaseDuck<MPerson> {
    getOrgEmployees: ActionCreator<['getOrgEmployees']>;
    sendPersonBio: ActionCreator<['setPersonBio']>;
    sendPersonPhones: ActionCreator<['setPersonPhoneNumbers']>;
    sendPersonEmails: ActionCreator<['setPersonEmailAddresses']>;
    sendPersonName: ActionCreator<['setPersonName']>;
    sendPersonAvatar: ActionCreator<['setPersonAvatar']>;
    sendPersonCover: ActionCreator<['setPersonCover']>;
    sendPersonPosition: ActionCreator<['setPersonPosition']>;
    sendPersonLocation: ActionCreator<['setPersonLocation']>;
    sendPersonTitleInfo: ActionCreator<['setPersonLocation', 'setPersonPosition', 'setPersonName']>;
    addPersonPhotos: ActionCreator<['addPersonPhoto']>;
    getPersonPhotos: ActionCreator<['getPersonPhotos']>;
    addPersonExperience: ActionCreator<['addPersonExperience']>;
    removePersonExperience: ActionCreator<['removePersonExperience']>;
    addPersonEducation: ActionCreator<['addPersonEducation']>;
    removePersonEducation: ActionCreator<['removePersonEducation']>;
    getSetPeopleById: ActionCreator<['getPeopleInfo']>;
    findPeopleUniversal: ActionCreator<['findPeopleUniversal']>;
    getPersonContacts: ActionCreator<['getPersonContacts']>;
    createPersonContact: ActionCreator<['createPersonContact']>;
    removePersonContact: ActionCreator<['deletePersonContact']>;
}


export const people: PeopleController = peopleDuck as PeopleController;

export const getOrgEmployees: ActionCreator<['getOrgEmployees']> = (organizationId: string, numberPerPage = 100, pageNumber?: number) => {
    const actions = [{
        name: 'getOrgEmployees',
        data: {
            organizationId,
            numberPerPage,
            pageNumber
        }
    }] as const;
    return {actions}
}
people.getOrgEmployees = getOrgEmployees;

export const sendPersonBio: ActionCreator<['setPersonBio']> = (bio: string, personId: string) => {
    const actions = [{
        name: 'setPersonBio',
        data: { bio, personId }
    }] as const;
    return { actions }
}
people.sendPersonBio = sendPersonBio;

export const sendPersonPhones: ActionCreator<['setPersonPhoneNumbers']> = (phones: IPhone[], personId: string) => {
    const actions = [{
        name: 'setPersonPhoneNumbers',
        data: { phones, personId }
    }] as const;
    return { actions }
}
people.sendPersonPhones = sendPersonPhones;

export const sendPersonEmails: ActionCreator<['setPersonEmailAddresses']> = (emails: IEmail[], personId: string) => {
    const actions = [{
        name: 'setPersonEmailAddresses',
        data: { emails, personId }
    }] as const;
    return { actions }
}
people.sendPersonEmails = sendPersonEmails

export const sendPersonName: ActionCreator<['setPersonName']> = (name: IName, personId: string) => {
    const actions = [{
        name: 'setPersonName',
        data: { name, personId }
    }] as const;
    return { actions }
}
people.sendPersonName = sendPersonName;

export const sendPersonAvatar: ActionCreator<['setPersonAvatar']> = async (file: Blob, personId: string, metaData: { [key: string]: any }) => {
    const newFile = await uploadDoc(file, 'avatar');
    if (!newFile?.success || !newFile.files?.avatar) return {cancel: true};

    const actions = [{
        name: 'setPersonAvatar',
        data: {
            avatar: {
                location: newFile.files?.avatar,
                description: '',
                metaData
            },
            personId
        }
    }] as const;
    return { actions }
}
people.sendPersonAvatar = sendPersonAvatar;

export const sendPersonCover: ActionCreator<['setPersonCover']> = async (file: File | undefined, personId: string, metaData: { [key: string]: any }) => {
    if (!file) {
        const actions = [{

            name: 'setPersonCover',
            data: {
                coverPhoto: undefined,
                personId
            }
        }] as const;
        return { actions }
    }
    
    const newFile = await uploadDoc(file, 'cover');
    if (!newFile?.success || !newFile.files?.cover) return {cancel: true};

    const actions = [{

        name: 'setPersonCover',
        data: {
            coverPhoto: {
                location: newFile.files?.cover,
                description: '',
                metaData
            },
            personId
        }
    }] as const;
    return { actions }
}
people.sendPersonCover = sendPersonCover;


export const sendPersonPosition: ActionCreator<['setPersonPosition']> = (position: string, personId: string) => {
    const actions = [{
        name: 'setPersonPosition',
        data: { position, personId }
    }] as const;
    return { actions }
}
people.sendPersonPosition = sendPersonPosition

export const sendPersonLocation: ActionCreator<['setPersonLocation']> = (location: string, personId: string) => {
    const actions = [{
        name: 'setPersonLocation',
        data: { location, personId }
    }] as const;
    return { actions }
}

people.sendPersonLocation = sendPersonLocation;


export const sendPersonTitleInfo: ActionCreator<['setPersonLocation', 'setPersonPosition', 'setPersonName']> = (location: string, position: string, name: IName, personId: string) => {
    const actions = [
        {
            name: 'setPersonLocation',
            data: { location, personId }
        },
        {
            name: 'setPersonPosition',
            data: { position, personId }
        },
        {
            name: 'setPersonName',
            data: { name, personId }
        }
    ] as const;
    return { actions }
}
people.sendPersonTitleInfo = sendPersonTitleInfo;

export const addPersonPhotos: ActionCreator<['addPersonPhoto']> = async (photos: ImageData[], personId: string) => {
        const actions = [] as any;
        for (const photo of photos) {
            if (!photo.name || !photo.data) throw new Error('Incorrect photo data format')
            const data = await uploadDoc(photo.data, 'photo', photo.name)
            if (data?.success) {
                const location = data.files.photo;
                if (location) {
                    const objPhoto: IPhoto = {
                        description: photo.name || '',
                        location: location,
                        metaData: {}
                    }
                    actions.push({
                        name: 'addPersonPhoto',
                        data: {
                            personId,
                            photo: objPhoto
                        }
                    })
                } else {
                    console.log('Did not receive location response for' + photo.name)
                }
            } 
        }
        return { actions }
}
people.addPersonPhotos = addPersonPhotos;

export const getPersonPhotos: ActionCreator<['getPersonPhotos']> = (personId: string, delimiter = '' ) => {
        const actions = [
            {
                name: 'getPersonPhotos',
                data: {
                    personId,
                    delimiter
                }
            }
        ] as const;

        return {actions}
}
people.getPersonPhotos = getPersonPhotos;

export const addPersonExperience: ActionCreator<['addPersonExperience']> = async (experience: IExperience & {photo: ImageData}, personId: string) => {
        let photoLocation;
        const photoData = experience.photo;
        if (photoData?.data) {
            const data = await uploadDoc(photoData.data, 'photo', photoData.name);
            photoLocation = data?.files?.photo
            if (!photoLocation) return { cancel: true }
        }
        const photo = photoLocation ? {
            location: photoLocation,
            description: photoData.name,
            metaData: {}
        } : undefined
        const actions = [{
            name: 'addPersonExperience',
            data: {
                experience: {
                    ...experience,
                    photo
                },
                personId
            }
        }] as const;
        return { actions }
    }
people.addPersonExperience = addPersonExperience;


export const removePersonExperience: ActionCreator<['removePersonExperience']> = (experienceId: string, personId: string) => {
    const actions = [{
        name: 'removePersonExperience',
        data: {
            experienceId,
            personId
        }
    }] as const;
    return { actions }
}
people.removePersonExperience = removePersonExperience;


export const addPersonEducation: ActionCreator<['addPersonEducation']> = async (education: IEducation & {photo: ImageData}, personId: string) => {
    let photoLocation;
    const photoData = education.photo;
    if (photoData?.data) {
        const data = await uploadDoc(photoData.data, 'photo', photoData.name);
        photoLocation = data?.files?.photo;
        if (!photoLocation) return { cancel: true }
    }
    const photo = photoLocation ? {
        location: photoLocation,
        description: photoData.name,
        metaData: {}
    } : undefined
    const actions = [{
        name: 'addPersonEducation',
        data: {
            education: {
                ...education,
                photo
            },
            personId
        }
    }] as const;
    return { actions }
}
people.addPersonEducation = addPersonEducation


export const removePersonEducation: ActionCreator<['removePersonEducation']> = (educationId: string, personId: string) => {
        const actions = [{
            name: 'removePersonEducation',
            data: {
                educationId,
                personId
            }
        }] as const;
        return {actions}
}
people.removePersonEducation = removePersonEducation;

export const getSetPeopleById: ActionCreator<['getPeopleInfo']> = (...peopleIds: string[]) => {
    if (peopleIds.length === 0) return {cancel: true};

    const actions = [{
        name: 'getPeopleInfo',
        data: peopleIds
    }] as const;
    return { actions }
}
people.getSetPeopleById = getSetPeopleById

export const findPeopleUniversal: ActionCreator<['findPeopleUniversal']> = (searchText: string, organizationIds: MOrganization['_id'][], numberPerPage = 100, pageNumber=0) => {
    const actions = [{
        name: 'findPeopleUniversal',
        data: {
            searchText,
            pageNumber,
            numberPerPage,
            organizationIds
        }
    }] as const;
    return { actions }
}
people.findPeopleUniversal = findPeopleUniversal;

interface CreatePersonContactArgs {
    name: IName,
    organizationId: MOrganization['_id'];
    position: string;
    location?: string;
    bio?: string;
    birthday?: string;
    email?: Omit<IEmail, '_id'>;
    phone?: Omit<IPhone, '_id'>;
}

export const createPersonContact: ActionCreator<['createPersonContact']> = (args: CreatePersonContactArgs) => {
    const actions = [{
        name: 'createPersonContact',
        data: {
            ...args
        }
    }] as const;
    return {actions}
}
people.createPersonContact = createPersonContact

interface ContactTableProps {
    pageNumber: number,
    sizePerPage: number,
    searchText: string,
    sorts: {
        name: string,
        organizationName: string,
        position: string
    }
}

export const getPersonContacts: ActionCreator<['getPersonContacts']> = (tableProps: ContactTableProps) => {
    const actions = [{
        name: 'getPersonContacts',
        data: tableProps
    }] as const;
    return {actions}
}
people.getPersonContacts = getPersonContacts;

export const removePersonContact: ActionCreator<['deletePersonContact']> = (personId) => {
    const actions = [{
        name: 'deletePersonContact',
        data: {
            personId
        }
    }] as const;
    return {actions}
}
people.removePersonContact = removePersonContact;

const thunks = {
    sendPersonBio,
    sendPersonPhones,
    sendPersonEmails,
    sendPersonName,
    sendPersonAvatar,
    sendPersonCover,
    sendPersonPosition,
    sendPersonLocation,
    sendPersonTitleInfo,
    addPersonPhotos,
    addPersonExperience,
    addPersonEducation,
    removePersonEducation,
    getSetPeopleById,
    getPersonContacts,
    createPersonContact,
    removePersonContact
}

export default thunks;