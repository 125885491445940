// const mergeById = (target: Record<string, any> | any[], source: Record<string, any> | any[], key?: string | undefined, replaceKeys?: string[]) => {
//     const targetType = Object.prototype.toString.call(target);
//     const sourceType = Object.prototype.toString.call(source);

import { concat, keyBy, mergeWith, unset } from "lodash";

//     switch (sourceType) {
//         case '[object Undefined]':
//             return;
//         case '[object Array]': {
//             if (!(targetType === sourceType)) {
//                 return target = source
//             }
//             for (const src of source) {
//                 const srcType = Object.prototype.toString.call(src);
//                 if (srcType === '[object Object]' && src._id) {
//                     //perform a search of the target for matching _id field
//                     let found = false
//                     for (const trg of target) {
//                         const trgType = Object.prototype.toString.call(trg);
//                         if (trgType === '[object Object]' && trg._id) {
//                             if ( trg._id === src._id ) {
//                                 mergeById(trg, src, undefined, replaceKeys)
//                                 found = true
//                                 break;
//                             }
//                         }
//                     }
//                     if (!found) target.push(src)
//                 } else {
//                     target.push(src)
//                 }
//             }
//             break;
//         }
//         case '[object Object]': {
//             if (!(targetType === sourceType)) {
//                 return target = source
//             } 
//             for (const key in source) {
//                 console.log('source', source)
//                 console.log('target', target)
//                 // if (replaceKeys?.includes(key)) {
//                 //     target[key] = source[key]
//                 // }
//                 const src = source[key];
//                 const trg = target[key];
//                 const srcType = Object.prototype.toString.call(src);
//                 const trgType = Object.prototype.toString.call(trg);
//                 if ((srcType === '[object Object]' || srcType === '[object Array]') && srcType === trgType) {
//                     mergeById(trg, src, key, replaceKeys)
//                 } else if (srcType === '[object Undefined]') {
//                     break;
//                 } else {
//                     target[key] = source[key]
//                 }
//             }
//         }

//     }
// }

// export default mergeById;


export function mergeById(replaceKeys?: string[], setKeys?: string[]) {
    function customizer(objVal: any, srcVal: any, key: string, object: any, source: any, stack: any): any {
        if ( replaceKeys?.includes(key) ) {
            return srcVal
        }
        if ( setKeys?.includes(key)) {
            const set = new Set()
            if (Array.isArray(srcVal)) {
                if (Array.isArray(objVal)) {
                    objVal.forEach( val => set.add(val))
                }
                srcVal.forEach( val => set.add(val))
            }
            return Array.from(set)
        }
        const srcType = Object.prototype.toString.call(srcVal)
        if ( srcType === '[object Object]') {
            const objType = Object.prototype.toString.call(objVal)
            if (objType === '[object Object]' && srcVal["~delete~"]) {
                console.log('found a delete key')
                delete object[key]
                return null
            }
            return
        } else if ( srcType === '[object Array]' ) {
            if (srcVal[0]) {
                if (Object.prototype.toString.call(srcVal[0]) === '[object Object]' && srcVal[0]['_id']) {
                    const merged = mergeWith(keyBy(objVal, '_id'), keyBy(srcVal, '_id'), mergeById(replaceKeys, setKeys));
                    const values = Object.values(merged).filter(s=>s);
                    return values
                }
                return concat(objVal || [], srcVal)
            }
            
        }
    }
    return customizer
}
