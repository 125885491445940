////// Packages //////////////////
import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

////// Local Components //////////
import NavbarItem from './NavbarItem';

////// Global Components /////////

////// CommonJS Helpers //////////
import { 
    adminRoutes, 
    financeRoutes, 
    homeRoutes, 
    managementRoutes, 
    reportingRoutes, 
    RouteDef, 
    socialRoutes, 
    timeRoutes, 
    stackRoutes 
} from '../../../routes/routes';
import { breakpoints } from 'helpers/utils';
import { navbarBreakPoint, topNavbarBreakpoint } from 'environ';
import { settings } from 'store/ducks/settings';

////// Custom Hooks //////////////

////// Thunks ////////////////////

interface Props {
}

const NavbarTopDropDownMenus: FC<Props> = () => {
  const dispatch = useDispatch()
  const isCombo = useSelector( state => state.app.settings?.isCombo )
  const isTopNav = useSelector( state => state.app.settings?.isTopNav )

  const handleSetNavbarCollapsed = () => {
    const windowWidth = window.innerWidth;
    isTopNav && !isCombo && windowWidth < breakpoints[topNavbarBreakpoint] && dispatch(settings.actions.setNavbarCollapsed(false));
    isCombo && windowWidth < breakpoints[navbarBreakPoint] && dispatch(settings.actions.setShowBurgerMenu(false));
  };

  const renderListMenu = (items?: RouteDef[]) => (
    items?.map( ({ to, name }, index) => (
      <DropdownItem tag={ Link } to={ to } key={ index } onClick={ handleSetNavbarCollapsed }>
        { name }
      </DropdownItem>
    ))
  );

  return (
    <>
      <NavbarItem linkTo="/home" title={homeRoutes.name} >
        { renderListMenu(homeRoutes.children) }
      </NavbarItem>

      <NavbarItem isDropdown title={managementRoutes.name} >
        { renderListMenu(managementRoutes.children!) }
      </NavbarItem>

      <NavbarItem isDropdown title={stackRoutes.name}>
      { renderListMenu(stackRoutes.children) }
      </NavbarItem>

      <NavbarItem isDropdown title={timeRoutes.name} >
        { renderListMenu(timeRoutes.children!) }
      </NavbarItem>

      <NavbarItem isDropdown title={financeRoutes.name} >
        { renderListMenu(financeRoutes.children!) }
      </NavbarItem>

      <NavbarItem isDropdown title={socialRoutes.name} >
        { renderListMenu(socialRoutes.children!) }
      </NavbarItem>

      <NavbarItem linkTo='#' title={reportingRoutes.name} />

      <NavbarItem isDropdown title={adminRoutes.name} >
        { renderListMenu(adminRoutes.children!) }
      </NavbarItem>
    </>
  );
};

NavbarTopDropDownMenus.propTypes = { setNavbarCollapsed: PropTypes.func.isRequired };

export default NavbarTopDropDownMenus;
