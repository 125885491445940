import { IName } from "store/types/models/id";

type OptionalString = string | false | undefined;

function space(words?: OptionalString[]) {
  if (words && Array.isArray(words) && words.length > 0) {
    return words.filter((x) => x).join(" ");
  }
  return "";
}

function commaSeparate(words?: OptionalString[]) {
  if (words && Array.isArray(words) && words.length > 0) {
    return words.filter((x) => x).join(", ");
  }
  return "";
}

function forceTitleCase(word?: string, use?: boolean) {
  if (use && word && typeof word === "string") {
    word = word[0].toUpperCase() + word.slice(1) || "";
  }
  return word || "";
}

/* objName is a name stored in the form 
{
    prefix: String?,
    first: String?,
    common: String?,
    middle: String?,
    last: String?,
    title: String?
} 

 options is an object of the form 
{
    prefix: Boolean?,
    first: Boolean? / 'abbreviated',
    common: Boolean? / 'abbreviated',
    middle: Boolean? / 'abbreviated',
    last: Boolean? / 'abbreviated',
    title: Boolean?,
    reverse: Boolean?, --changes name to  last, first middle, prefix, title format
    coerceUpper: Boolean? -- ensures that all names are returned in upper case
} 

collisions where 
    objName is undefined and options is true or
    objName is defined and options are false
are resolved by ignoring the part with collision

collision of options
    first: true AND common: true
is resolved by printing the common name instead of the first name

*/
export interface MakeNameProps {
  prefix?: boolean;
  first?: boolean | "abbreviated";
  common?: boolean | "abbreviated";
  middle?: boolean | "abbreviated";
  last?: boolean | "abbreviated";
  title?: boolean;
  reverse?: boolean;
  coerceUpper?: boolean;
  flat?: boolean;
}

function makeName(
  objName: IName | undefined,
  {
    prefix,
    first,
    common,
    middle,
    last,
    title,
    reverse,
    coerceUpper,
    flat
  }: MakeNameProps = { first: true, common: true, last: true }
) {
  if (typeof objName !== "object") return "";
  if (!objName) return "";
  const p = forceTitleCase(objName.prefix, coerceUpper);
  const f = forceTitleCase(objName.first, coerceUpper);
  const c = forceTitleCase(objName.common, coerceUpper);
  const m = forceTitleCase(objName.middle, coerceUpper);
  const l = forceTitleCase(objName.last, coerceUpper);
  const t = forceTitleCase(objName.title, coerceUpper);
  
  if (flat) return (['prefix', 'first', 'common', 'middle', 'last', 'title'] as const).map(a => objName?.[a]).filter(s=>s).join(' ')

  if (reverse) {
    return commaSeparate([
      last && l && (last === "abbreviated" ? l[0] + "." : l),
      space([
        common && c && (common === "abbreviated" ? c[0] + "." : c),
        first &&
          (!common || !c) &&
          f &&
          (first === "abbreviated" ? f[0] + "." : f),
        middle && m && (middle === "abbreviated" ? m[0] + "." : m)
      ]),
      prefix && p,
      title && t
    ]);
  } else {
    return commaSeparate([
      space([
        prefix && p,
        common && (c || "") && (common === "abbreviated" ? c[0] + "." : c),
        first &&
          (!common || !c) &&
          (f || "") &&
          (first === "abbreviated" ? f[0] + "." : f),
        middle && (m || "") && (middle === "abbreviated" ? m[0] + "." : m),
        last && l && (last === "abbreviated" ? l[0] + "." : l)
      ]),
      title && t
    ]);
  }
}

export default makeName;
