// core components
import { useEffect, FC } from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

// user components
import AuthGateway from './AuthGateway';
import { CloseButton, Fade } from './components/Toast';

// stylesheets
import toggleStylesheet from './helpers/toggleStylesheet';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';

// redux store
import {useSelector, useDispatch} from 'react-redux';
import { settings } from 'store/ducks/settings'
import Loading from 'layouts/test/Loading';
// import AVContainer from 'layouts/av/AVContainer';
// import PeerProvider from 'layouts/av/PeerProvider';


const App: FC<any> = ({}) => { // eslint-disable-line no-empty-pattern
    const isRTL = useSelector( state => state.app.settings?.isRTL );
    const isLoaded = useSelector( state => state.app.settings?.isLoaded );
    const isDark = useSelector( state => state.app.settings?.isDark );

    const dispatch = useDispatch();
    

    useEffect(() => {
      dispatch( settings.actions.setLoaded( false ) );

      toggleStylesheet(
          { isRTL, isDark }, 
          () => dispatch( settings.actions.setLoaded( true ) )
      );

    }, [isRTL, isDark])
    
    return (
      <Router basename={process.env.PUBLIC_URL}>
        {isLoaded 
        ? <>
        
            <AuthGateway />
            <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
            
          </>
          : <Loading />
        }
      </Router>
    )
}

export default App;
