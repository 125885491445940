////// Packages //////////////////
import React, { useState } from 'react';
import { Card, Dropdown, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

////// Local Components //////////

////// Global Components /////////
import FalconCardHeader from 'components/FalconCardHeader';
import Notification from 'compositions/Notification';

////// CommonJS Helpers //////////
import { isIterableArray } from 'helpers/utils';
import autoDrop from 'helpers/autoDrop';

////// Custom Hooks //////////////
import useFetch from 'hooks/useFetch';

////// Thunks ////////////////////


const NotificationDropdown = () => {
  // State
  const { post, loading, error } = useFetch();

  const [newNotifications, setNewNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(false);

  const autoDropdown = autoDrop(setIsOpen)

  // Handler
  const handleToggle = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const updateNotifications = async (sendData) => {
    const data = await post(sendData, '/notifications');
    setNewNotifications( data.filter( e =>  e.unread ) );
    setReadNotifications( data.filter( e => !e.unread ) );
    return data;
  }

  const markAsRead = async e => {
    e.preventDefault();
    const sendData = {
      items: newNotifications.map( n => n.id ), 
      action: 'mark', 
      unread: false
    }
    await updateNotifications(sendData);
  };

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={isOpen}
      toggle={handleToggle}
      onMouseOver={autoDropdown(true, true)}
      onMouseLeave={autoDropdown(false, false)}
    >
      <DropdownToggle
        nav
        className={classNames('px-1', {
          'notification-indicator notification-indicator-primary': !isAllRead
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <Card className="card-notification shadow-none" style={{ maxWidth: '20rem' }}>
          <FalconCardHeader className="card-header" title="Notifications" titleTag="h6" light={false}>
            <Link className="card-link font-weight-normal" to="#!" onClick={markAsRead}>
              Mark all as read
            </Link>
          </FalconCardHeader>
          <ListGroup flush className="font-weight-normal fs--1">
            <div className="list-group-title">NEW</div>
            {isIterableArray(newNotifications) &&
              newNotifications.map((notification, index) => (
                <ListGroupItem key={index} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroupItem>
              ))}
            <div className="list-group-title">EARLIER</div>
            {isIterableArray(readNotifications) &&
              readNotifications.map((notification, index) => (
                <ListGroupItem key={index} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroupItem>
              ))}
          </ListGroup>
          <div className="card-footer text-center border-top" onClick={handleToggle}>
            <Link className="card-link d-block" to="/pages/notifications">
              View all
            </Link>
          </div>
        </Card>
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationDropdown;
