import { AnyAction } from "redux";
import { MBoard } from "store/types/models/stack";

export enum TYPES {
    SET='happin/forms/geoSearch/SET',
    RESET='happin/forms/geoSearch/SET'
}

export function reducer(state: MBoard['_id'][] = [], action: AnyAction) {
    switch(action?.type) {
        case TYPES.SET:
            return action.payload || [];
        case TYPES.RESET:
            return [];
        default:
            return state
    }
}