'use strict';

import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";

export const prefix = 'hap' as IconPrefix;
export const iconName = 'board-tripod' as IconName;
export const width = 328.16;
export const height = 323.49;
export const ligatures = [];
export const unicode = 'f377';
export const svgPathData ="M38.51,27.79A5.49,5.49,0,0,1,44,22.3H311.43a5.49,5.49,0,0,1,5.49,5.49V162.23h24.67V15.91A15.93,15.93,0,0,0,325.68,0H29.75A15.93,15.93,0,0,0,13.84,15.91V162.23H38.51ZM355.43,181.26v17.66a5.42,5.42,0,0,1-5.42,5.42h-8.42v2.8a17.44,17.44,0,0,1-17.45,17.44h-81.7l41.36,71.63a5.56,5.56,0,0,1-2,7.6L267.64,312a5.57,5.57,0,0,1-7.6-2l-49.28-85.35H191.7V332.42a5.5,5.5,0,0,1-5.5,5.5H170.09a5.56,5.56,0,0,1-5.56-5.57V224.58H144.67L95.39,309.93a5.57,5.57,0,0,1-7.6,2l-14.12-8.16a5.56,5.56,0,0,1-2-7.6L113,224.58H31.21a17.37,17.37,0,0,1-17.37-17.37v-2.87H5.42A5.42,5.42,0,0,1,0,198.92V181.26a5.42,5.42,0,0,1,5.42-5.42H211a5,5,0,0,1-.14-1.1V150.09a4.8,4.8,0,0,1,4.8-4.8h61.53A4.81,4.81,0,0,1,282,150.1v24.64a5,5,0,0,1-.14,1.1H350A5.42,5.42,0,0,1,355.43,181.26Z"

export const definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

const haBoardTripod = definition;
export default haBoardTripod;