// mounting, polyfills, redux store provider, and redux-persist reloading gate

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store, persistor } from "store/storeCreator";
import { PersistGate } from 'redux-persist/integration/react';

//import DBConfig from 'store/indexedDb/DBConfig';

import './helpers/initFA';

//initDB(DBConfig);

ReactDOM.render(
  <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
  </Provider>
  ,
  document.getElementById('main')
);