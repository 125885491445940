import { BaseDuck } from './BaseDuck';

const DEFAULT_STATE = {
    isFluid: false,
    isRTL: false,
    isDark: false,
    isTopNav: false,
    isVertical: true,
    get isCombo() {
      return this.isVertical && this.isTopNav;
    },
    showBurgerMenu: false, // controls showing vertical nav on mobile
    currency: '$',
    isNavbarVerticalCollapsed: false,
    navbarStyle: 'transparent',
    isLoaded: false,
    isOpenSidePanel: false,
    navbarCollapsed: false,
    kanban: {
        categories: []
    }
}

export const settings = new BaseDuck<any>('settings', DEFAULT_STATE);
settings.replaceKeys = ['categories'];

const setFluid = (value: boolean) => settings.insert(value, 'isFluid')
settings.actions.setFluid = setFluid

const setDark = (value: boolean) => settings.insert(value, 'isDark')
settings.actions.setDark = setDark

const setRtl = (value: boolean) => settings.insert(value, 'isRTL')
settings.actions.setRtl = setRtl

const setTopNav = (value: boolean) => settings.insert(value, 'isTopNav')
settings.actions.setTopNav = setTopNav

const setVertical = (value: boolean) => settings.insert(value, 'isVertical')
settings.actions.setVertical = setVertical

const setVerticalCollapsed = (value: boolean) => settings.insert(value, 'isNavbarVerticalCollapsed')
settings.actions.setVerticalCollapsed = setVerticalCollapsed

const setCurrency = (value: string) => settings.insert(value, 'currency')
settings.actions.setCurrency = setCurrency

const setShowBurgerMenu = (value: boolean) => settings.insert(value, 'showBurgerMenu')
settings.actions.setShowBurgerMenu = setShowBurgerMenu

const setNavbarStyle = (value: string) => settings.insert(value, 'navbarStyle')
settings.actions.setNavbarStyle = setNavbarStyle

const setLoaded = (value: boolean) => settings.insert(value, 'isLoaded')
settings.actions.setLoaded = setLoaded

const setOpenSidePanel = (value: boolean) => settings.insert(value, 'isOpenSidePanel')
settings.actions.setOpenSidePanel = setOpenSidePanel

const setNavbarCollapsed = (value: boolean) => settings.insert(value, 'isNavbarCollapsed')
settings.actions.setNavbarCollapsed = setNavbarCollapsed