////// Packages //////////////////
import { CustomInput, Nav, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

////// Local Components //////////
import ProfileDropdown from './ProfileDropdown';
import NotificationDropdown from './NotificationDropdown';

////// Global Components /////////

////// CommonJS Helpers //////////
import { settings } from 'store/ducks/settings';
import useWS from 'store/hooks/useWS';
import PinnedAssignment from './PinnedAssignment';

////// Custom Hooks //////////////

////// Thunks ////////////////////


const TopNavRightSideNavItem = () => {
  const isTopNav = useSelector( state => state.app.settings?.isTopNav );
  const isDark = useSelector( state => state.app.settings?.isDark )
  const dispatch= useDispatch();
  const wsError = useSelector( state => state.ws.errorMessage);
  const wsStatus = useSelector( state => state.ws.status);
  const { socket } = useWS();
  
  return (
    <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
      <NavItem>
        <PinnedAssignment size="s"/>
      </NavItem>
      <NavItem className="d-none d-sm-inline mx-2">
        { wsStatus === 'open' 
          ? <h6 className="m-0">Connected <FontAwesomeIcon icon="check" className="text-success" /></h6>
          : wsStatus === 'closed'
            ? <h6 className="m-0">Disconnected <FontAwesomeIcon icon="times" className="text-secondary" /></h6>
            : <h6 className="m-0">Error: {wsError} <FontAwesomeIcon icon="exclamation-circle" className="text-danger" /></h6>  
        }
      </NavItem>
      <NavItem className="d-none d-md-inline mx-2">
        <CustomInput
          type="switch"
          id="customSwitch"
          name="customSwitch"
          label="Dark Mode"
          checked={!!isDark}
          onChange={e => dispatch(settings.actions.setDark(e.target.checked))}
        />
      </NavItem>
      {isTopNav && (
        <NavItem className={`p-2 px-lg-0 cursor-pointer d-none d-sm-block`}>
          <NavLink tag={Link} to="/changelog" id="changelog">
            <FontAwesomeIcon icon="code-branch" transform="right-6 grow-4" />
          </NavLink>
          <UncontrolledTooltip autohide={false} placement="left" target="changelog">
            Changelog
          </UncontrolledTooltip>
        </NavItem>
      )}
      
      <NotificationDropdown />
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
